import { Box, DropdownOption, Stack } from "design-system";
import { LiabilitySection } from "./LiabilitySection";
import { array, option } from "fp-ts";
import { pipe, constFalse, constTrue, constVoid } from "fp-ts/function";
import { Option } from "fp-ts/Option";
import {
  ConsumerLoanOrMortgage,
  CreditType,
  LoanList,
  ReworkConsumerLoanOrMortgage,
} from "../../domain";
import { useFormatMessage } from "../../../intl";
import { ConsumerLoanOrMortgageForm } from "./ConsumerLoanOrMortgageForm";
import { SelectedApplicant } from "../../mortgageDashboardUtils";
import { EditSection } from "./EditSection";
import { NonEmptyString } from "io-ts-types/NonEmptyString";
import { AmountLimit } from "../../api";
import { LiabilitiesVariant } from "./Liabilities";

type Props = {
  isInteractingWithAnyForm: boolean;
  consumerLoansOrMortgagesList: Array<ConsumerLoanOrMortgage>;
  selectedApplicant: SelectedApplicant;
  isAdding: boolean;
  isACPhase: boolean;
  isViewMode: boolean;
  editingRecordID: Option<NonEmptyString>;

  onEdit: (credit: ConsumerLoanOrMortgage) => unknown;
  onCancel: () => unknown;
  onAdding: (type: keyof LoanList) => unknown;
  onAdd: (coverdraft: ConsumerLoanOrMortgage) => unknown;
  onRemove: (recordId: NonEmptyString) => unknown;
  onEditing: (recordId: NonEmptyString) => unknown;
  onCheck: (credit: ConsumerLoanOrMortgage, val: boolean) => unknown;
  providersList: DropdownOption<NonEmptyString>[];
  amountLimits: {
    [key in
      | "monthlyInstallmentAmount"
      | "mtgCurrentBalance"
      | "mtgOriginalLoanAmount"]: AmountLimit;
  };
  variant: LiabilitiesVariant;
  getReworkConsumerLoan: (
    recordId: NonEmptyString
  ) => Option<ReworkConsumerLoanOrMortgage>;
};

export function MortgageConsumerLoanStack(props: Props) {
  const formatMessage = useFormatMessage();

  const getLoans = (creditTypes: CreditType[], manuallyAdded: boolean) =>
    pipe(
      props.consumerLoansOrMortgagesList,
      array.filter(
        item =>
          item.manuallyAdded === manuallyAdded &&
          pipe(
            item.creditType,
            option.exists(creditType => creditTypes.includes(creditType))
          )
      )
    );

  const mortgagesList = getLoans(["MORTGAGE_LOAN"], false);
  const consumerLoansList = getLoans(["CONSUMER_LOAN"], false);
  const manuallyAddedConsumerLoansOrMortgages = getLoans(
    ["CONSUMER_LOAN", "MORTGAGE_LOAN"],
    true
  );

  const mortgagesConsumerLoanFormat = (credit: ConsumerLoanOrMortgage) => {
    const reworkConsumerLoan = props.getReworkConsumerLoan(credit.recordId);

    return (
      <Box grow shrink column key={credit.recordId}>
        <ConsumerLoanOrMortgageForm
          initialValues={option.some(credit)}
          onSave={props.onEdit}
          onCancel={props.onCancel}
          onEdit={() => props.onEditing(credit.recordId)}
          onRemove={() => props.onRemove(credit.recordId)}
          isACPhase={props.isACPhase}
          isInteractingWithAnyForm={props.isInteractingWithAnyForm}
          selectedApplicant={props.selectedApplicant}
          providersList={props.providersList}
          amountLimits={props.amountLimits}
          edit={
            !props.isViewMode &&
            pipe(
              props.editingRecordID,
              option.filter(id => id === credit.recordId),
              option.fold(constFalse, constTrue)
            )
          }
          isViewMode={props.isViewMode}
          variant={props.variant}
          reworkConsumerLoan={reworkConsumerLoan}
        />
      </Box>
    );
  };

  const newMortgageConsumerLoanData = {
    label: formatMessage(
      "Mortgage.CBResults.Liabilities.addMortgagesOrConsumerLoans"
    ),
    action: () => props.onAdding("consumerLoansOrMortgagesList"),
    disabled: props.isInteractingWithAnyForm,
  };

  return (
    <Stack key="mortgagesConsumerLoans" units={4} column>
      <LiabilitySection
        key="mortgages"
        title={formatMessage("Mortgage.CBResults.Liabilities.mortgages")}
        children={mortgagesList.map(mortgagesConsumerLoanFormat)}
        addNew={option.none}
      />
      <LiabilitySection
        key="consumerLoans"
        title={formatMessage("Mortgage.CBResults.Liabilities.consumerLoans")}
        children={consumerLoansList.map(mortgagesConsumerLoanFormat)}
        addNew={pipe(
          newMortgageConsumerLoanData,
          option.fromPredicate(
            () =>
              !props.isAdding &&
              !props.isViewMode &&
              manuallyAddedConsumerLoansOrMortgages.length === 0
          )
        )}
      />
      {manuallyAddedConsumerLoansOrMortgages.length > 0 && (
        <LiabilitySection
          key="manuallyAddedConsumerLoansOrMortgages"
          title={formatMessage(
            "Mortgage.CBResults.Liabilities.manuallyAddedConsumerLoansOrMortgages"
          )}
          children={manuallyAddedConsumerLoansOrMortgages.map(
            mortgagesConsumerLoanFormat
          )}
          addNew={pipe(
            newMortgageConsumerLoanData,
            option.fromPredicate(() => !props.isAdding && !props.isViewMode)
          )}
        />
      )}
      {props.isAdding && (
        <EditSection
          title={formatMessage(
            "Liabilities.AddConsumerLoanOrMortgage.formSectionTitle"
          )}
        >
          <ConsumerLoanOrMortgageForm
            initialValues={option.none}
            onSave={props.onAdd}
            onCancel={props.onCancel}
            onEdit={constVoid}
            onRemove={constVoid}
            isACPhase={props.isACPhase}
            isInteractingWithAnyForm={props.isInteractingWithAnyForm}
            selectedApplicant={props.selectedApplicant}
            providersList={props.providersList}
            amountLimits={props.amountLimits}
            edit={!props.isViewMode}
            isViewMode={props.isViewMode}
            variant={props.variant}
            reworkConsumerLoan={option.none}
          />
        </EditSection>
      )}
    </Stack>
  );
}
