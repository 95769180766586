import {
  FileContent,
  LocalizedString,
  NonNegativeInteger,
  PositiveInteger,
} from "design-system";
import { eq, option } from "fp-ts";
import { constFalse, constTrue } from "fp-ts/function";
import { Semigroup } from "fp-ts/Semigroup";
import * as t from "io-ts";
import { NonEmptyString } from "io-ts-types/lib/NonEmptyString";
import { optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import { withFallback } from "io-ts-types/lib/withFallback";
import { apiCall } from "../APICall";
import {
  eqWithCoApplicant,
  GenericError,
  MaritalStatus,
  MoneyAmount,
  optionFromUndefined,
  withCoApplicant,
} from "../globalDomain";
import { TermsAndConditionsOutput } from "../Common/PreContractualDocuments/api";
import {
  CheckExistingClientInput,
  CheckExistingClientOutput,
  eqCheckExistingClientInput,
} from "../IdUpload/ClientData/api";
import { KYCAnswers, KYCAnswers2 } from "../KYC/domain";
import {
  eqOtpGenerationInput,
  eqOtpVerifyInput,
  OtpGenerationError,
  OtpGenerationInput,
  OtpGenerationOutput,
  OtpVerifyInput,
} from "../OTP/domain";
import {
  AdditionalIncome,
  BusinessSector,
  DeleteAdditionalIncome,
  FreelancerType,
  GetIncome,
  IncomeFull,
  JobPosition,
  SaveIncome,
  ThirdParty,
} from "./Bonita/Income/domain";
import {
  AdditionalDetails,
  ApplicantDetailsConfig,
  ApplicantProfile,
  ApplicantRegistersData,
  ApplicantsRecordC,
  ApplicationLimitError,
  ApplicationList,
  ApplicationStatus,
  AssignedUser,
  BankersList,
  BonitaApplicantState,
  BonitaError,
  BonitaResults,
  BranchesList,
  ClientProfileError,
  CPIAnswers,
  CPIData,
  CreditBureauStatus,
  eqWithApplicantIndex,
  Expenses,
  FinalDecisionStatus,
  HouseholdInfo,
  LoanList,
  MTG3POtpVerifyError,
  MTGSubmitApplicationError,
  OfferError,
  OfferWarning,
  PreliminaryDecisionStatus,
  PropertyAndAppraisalDetailsResponse,
  StatusColor,
  TotalLiabilitiesInput,
  UserType,
  withApplicantIndex,
  ReworkActions,
  ReworkData,
  AssignedBrokerForBanker,
} from "./domain";
import { Eq } from "fp-ts/Eq";
import {
  eqLinkGenerationInput,
  LinkGenerationError,
  LinkGenerationInput,
  LinkGenerationOutput,
  eqOtpGenerationInput as eqOtpGenerationInputBase,
  OtpGenerationError as OtpGenerationErrorBase,
  OtpGenerationInput as OtpGenerationInputBase,
  OTPGenerationOutput as OTPGenerationOutputBase,
  OtpVerifyError as OtpVerifyErrorBase,
  OtpVerifyInput as OtpVerifyInputBase,
} from "../PhoneAndEmailVerification/api";

const NewApplicationInput = t.type({
  name: NonEmptyString,
  surname: NonEmptyString,
  phoneNumber: NonEmptyString,
});

const eqNewApplicationInput = eq.getStructEq({
  name: eq.eqString,
  surname: eq.eqString,
  phoneNumber: eq.eqString,
});

export const NewApplicationOutput = t.type({
  applicationId: t.string,
});

export const createNewApplication = apiCall({
  inputCodec: NewApplicationInput,
  inputEq: eqNewApplicationInput,
  outputCodec: NewApplicationOutput,
  path: ["packages", "mortgage", "application", "createNew"],
});

export const createNewExistingClient = apiCall({
  inputCodec: NewApplicationInput,
  inputEq: eqNewApplicationInput,
  outputCodec: NewApplicationOutput,
  path: ["packages", "mortgage", "application", "createNewExistingClient"],
});

export const AddCoapplicantInput = t.type({
  name: NonEmptyString,
  surname: NonEmptyString,
  coapplicantLabel: LocalizedString,
});

export const addCoapplicant = apiCall({
  path: ["packages", "mortgage", "application", "addCoapplicant"],
  inputCodec: AddCoapplicantInput,
  inputEq: eq.fromEquals(constFalse),
  outputCodec: t.type({
    applicantIndex: PositiveInteger,
  }),
});

export const renounce = apiCall({
  path: ["packages", "mortgage", "application", "renounce"],
  inputCodec: t.void,
  inputEq: eq.fromEquals(constFalse),
  outputCodec: t.unknown,
});

export const removeApplication = apiCall({
  path: ["packages", "mortgage", "application", "cancel"],
  inputCodec: t.void,
  inputEq: eq.fromEquals(constFalse),
  outputCodec: t.unknown,
});

const RemoveCoapplicantInput = t.type({
  coapplicantIndex: t.string,
});

export const removeCoapplicant = apiCall({
  path: ["packages", "mortgage", "removeCoapplicant"],
  inputCodec: RemoveCoapplicantInput,
  inputEq: eq.fromEquals(constFalse),
  outputCodec: t.unknown,
});

const CancelIdUploadInput = t.type({
  applicantIndex: t.string,
});

export const cancelIdUpload = apiCall({
  path: ["packages", "mortgage", "client", "cancelIdUpload"],
  inputCodec: CancelIdUploadInput,
  inputEq: eq.fromEquals(constFalse),
  outputCodec: t.unknown,
});

export const JobPositionsOutput = t.array(JobPosition);
export const jobPositions = apiCall({
  path: ["packages", "mortgage", "bonita", "income", "jobPositions"],
  inputCodec: t.void,
  inputEq: eq.fromEquals(constFalse),
  outputCodec: JobPositionsOutput,
});

export const FreelancerTypesOutput = t.array(FreelancerType);

export const FreelancerTypes = apiCall({
  path: ["packages", "mortgage", "bonita", "income", "freelancerTypes"],
  inputCodec: t.void,
  inputEq: eq.fromEquals(constFalse),
  outputCodec: FreelancerTypesOutput,
});

export const CurrenciesOutput = t.array(t.string);
export const currencies = apiCall({
  path: ["packages", "mortgage", "bonita", "income", "currencies"],
  inputCodec: t.void,
  inputEq: eq.fromEquals(constFalse),
  outputCodec: CurrenciesOutput,
});

export const ThirdPartiesOutput = t.array(ThirdParty);
export const thirdParties = apiCall({
  path: ["packages", "mortgage", "bonita", "income", "thirdParty"],
  inputCodec: t.void,
  inputEq: eq.fromEquals(constFalse),
  outputCodec: ThirdPartiesOutput,
});

export const DataForClientWithoutIcoOutput = t.type(
  {
    phone: optionFromUndefined(t.string),
    country: optionFromUndefined(t.string),
    city: optionFromUndefined(t.string),
    street: optionFromUndefined(t.string),
    houseNumber: optionFromUndefined(t.string),
    zipCode: optionFromUndefined(t.string),
  },
  "DataForClientWithoutIcoOutput"
);
export const dataForClientWithoutIco = apiCall({
  path: ["packages", "mortgage", "bonita", "income", "dataForClientWithoutIco"],
  inputCodec: withApplicantIndex(t.unknown),
  inputEq: eq.fromEquals(constTrue),
  outputCodec: DataForClientWithoutIcoOutput,
});

export const CheckStatus = t.keyof({
  ToDo: true,
  InProgress: true,
  Complete: true,
  Warning: true,
  Error: true,
  InvalidError: true,
  Timeout: true,
});

export type CheckStatus = t.TypeOf<typeof CheckStatus>;

export const semigroupCheckStatus: Semigroup<CheckStatus> = {
  concat: (a, b) => (a === "Complete" ? (b === "Complete" ? a : b) : a),
};

const BusinessSectorsOutput = t.array(BusinessSector);

export const businessSectors = apiCall({
  path: ["packages", "mortgage", "bonita", "income", "businessSectors"],
  inputCodec: t.void,
  inputEq: eq.fromEquals(constFalse),
  outputCodec: BusinessSectorsOutput,
});

const BonitaStatus = t.union([CheckStatus, t.literal("EvaluateInProgress")]);
export type BonitaStatus = t.TypeOf<typeof BonitaStatus>;

export const ClientIdCheckStatus = t.union([
  CheckStatus,
  t.literal("InvalidIdError"),
]);
export type ClientIdCheckStatus = t.TypeOf<typeof ClientIdCheckStatus>;

export const ClientProfilePermissions = ApplicantsRecordC(
  t.type({
    applicantIndex: t.string,
    canEditEmail: t.boolean,
  })
);

export type ClientProfilePermissions = t.TypeOf<
  typeof ClientProfilePermissions
>;

const basicApplicationChecks = {
  clientProfile: t.type(
    {
      contacts: CheckStatus,
      clientId: ClientIdCheckStatus,
      errors: optionFromUndefined(ClientProfileError),
      permissions: ClientProfilePermissions,
    },
    "ClientProfile"
  ),
  offer: t.type(
    {
      offer: CheckStatus,
      errorType: optionFromUndefined(OfferError),
      warningType: optionFromUndefined(OfferWarning),
      showSimpleRefinancing: t.boolean,
    },
    "Offer"
  ),
  bonita: t.type(
    {
      bonita: BonitaStatus,
      simplifiedBonita: optionFromUndefined(BonitaStatus),
      errorType: optionFromUndefined(BonitaError),
    },
    "Bonita"
  ),
  registers: t.type(
    {
      registers: CheckStatus,
      refinancingLoan: optionFromUndefined(CheckStatus),
      existingApplications: optionFromUndefined(CheckStatus),
      mandatoryFields: optionFromUndefined(CheckStatus),
      isNotSentToLf: optionFromUndefined(t.boolean),
    },
    "Registers"
  ),
  additionalDetails: t.type(
    {
      cpiQuestions: optionFromUndefined(CheckStatus),
      additionalDetails: CheckStatus,
      legalData: optionFromUndefined(CheckStatus),
      healthQuestionnaire: optionFromUndefined(CheckStatus),
    },
    "AdditionalDetails"
  ),
  isApplicationLocked: t.boolean,
  controlCheck: t.boolean,
  canSubmitApplication: optionFromUndefined(t.boolean),
  reworkCategoryQuestion: t.boolean,
};

export const DipApplicationChecks = t.strict({
  applicationPhase: t.literal("DIP"),
  ...basicApplicationChecks,
});

export const PostDipApplicationChecks = t.strict({
  ...basicApplicationChecks,
  applicationPhase: t.keyof({
    AC: true,
    PD: true,
    FD: true,
    REWORK: true,
    CHANGE_PROCESS: true,
  }),
  purposeStatus: t.type({
    status: CheckStatus,
  }),
  propertyAndAppraisal: t.type({
    property: CheckStatus,
    appraisal: CheckStatus,
    valueOfProperty: CheckStatus,
  }),
  documents: t.type({
    status: CheckStatus,
  }),
});

export type DipApplicationChecks = t.TypeOf<typeof DipApplicationChecks>;
export type PostDipApplicationChecks = t.TypeOf<
  typeof PostDipApplicationChecks
>;

const ApplicationChecksOutput = t.union([
  DipApplicationChecks,
  PostDipApplicationChecks,
]);

export type ApplicationChecksOutput = t.TypeOf<typeof ApplicationChecksOutput>;

export type ApplicationPhase = ApplicationChecksOutput["applicationPhase"];

export const applicationChecks = apiCall({
  inputCodec: t.void,
  inputEq: eq.fromEquals(constFalse),
  path: ["packages", "mortgage", "application", "checks"],
  outputCodec: ApplicationChecksOutput,
});

export const saveApplication = apiCall({
  inputCodec: t.void,
  inputEq: eq.fromEquals(constFalse),
  path: ["packages", "mortgage", "application", "save"],
});

export const submitApplication = apiCall({
  inputCodec: t.void,
  inputEq: eq.fromEquals(constFalse),
  path: ["packages", "mortgage", "application", "submit"],
  errorCodec: MTGSubmitApplicationError,
});

const ApplicationStatusOutput = t.union(
  [
    t.type({
      responseStatus: t.literal("NOT_SUBMITTED"),
    }),
    t.type({
      responseStatus: t.literal("NOT_RECEIVED"),
    }),
    t.type({
      responseStatus: t.literal("RECEIVED"),
      applicationStatus: ApplicationStatus,
      overLimitDecisionReasons: optionFromUndefined(t.array(LocalizedString)),
    }),
  ],
  "ApplicationStatus"
);

export type ApplicationStatusOutput = t.TypeOf<typeof ApplicationStatusOutput>;

export const applicationStatus = apiCall({
  inputCodec: t.void,
  inputEq: eq.fromEquals(constFalse),
  path: ["packages", "mortgage", "application", "status"],
  outputCodec: ApplicationStatusOutput,
});

export const proceedWithAcDashboard = apiCall({
  inputCodec: t.void,
  inputEq: eq.fromEquals(constFalse),
  path: ["packages", "mortgage", "application", "proceedWithAcDashboard"],
  outputCodec: t.null,
});

const ClientProfileOutput = ApplicantsRecordC(ApplicantProfile);

export type ClientProfileOutput = t.TypeOf<typeof ClientProfileOutput>;

export const clientProfile = apiCall({
  inputCodec: t.void,
  inputEq: eq.fromEquals(constFalse),
  path: ["packages", "mortgage", "client", "profile"],
  outputCodec: ClientProfileOutput,
});

export const openClient = apiCall({
  inputCodec: withApplicantIndex(t.unknown),
  inputEq: eq.fromEquals(constFalse),
  path: ["packages", "mortgage", "client", "openClient"],
});

export const existingClient = apiCall({
  inputCodec: withApplicantIndex(t.unknown),
  inputEq: eq.fromEquals(constFalse),
  path: ["packages", "mortgage", "client", "existingClient"],
});

const ProceedWithRegistersOutput = t.type(
  {
    completedOffer: t.boolean,
    mainApplicantInitiatedCB: t.boolean,
    applicantsRegistersData: ApplicantsRecordC(ApplicantRegistersData),
    selectedOfferLoanAmount: optionFromUndefined(MoneyAmount),
  },
  "ProceedWithRegisters"
);

export type ProceedWithRegistersOutput = t.TypeOf<
  typeof ProceedWithRegistersOutput
>;

export const proceedWithRegisters = apiCall({
  inputEq: eq.fromEquals(constFalse),
  inputCodec: t.void,
  path: ["packages", "mortgage", "proceedWithRegisters"],
  outputCodec: ProceedWithRegistersOutput,
});

export const RegistersMemorandumInput = withApplicantIndex(
  t.type({
    firstOptionCBConsent: t.boolean,
    secondOptionCBConsent: t.boolean,
  })
);

export type RegistersMemorandumInput = t.TypeOf<
  typeof RegistersMemorandumInput
>;

const eqRegistersMemorandumInput = eqWithApplicantIndex(
  eq.getStructEq({
    firstOptionCBConsent: eq.eqBoolean,
    secondOptionCBConsent: eq.eqBoolean,
  })
);

export const registersMemorandum = apiCall({
  inputEq: eqRegistersMemorandumInput,
  inputCodec: RegistersMemorandumInput,
  outputCodec: t.unknown,
  path: ["packages", "mortgage", "registers", "memorandum"],
});

const RegistersOtpGenerationInput = withCoApplicant(OtpGenerationInput);

export type RegistersOtpGenerationInput = t.TypeOf<
  typeof RegistersOtpGenerationInput
>;

const eqRegistersOtpGenerationInput = eqWithCoApplicant(eqOtpGenerationInput);

export const RegistersOtpGenerationError = t.union([
  OtpGenerationError,
  ApplicationLimitError,
  GenericError,
]);

export type RegistersOtpGenerationError = t.TypeOf<
  typeof RegistersOtpGenerationError
>;

export const registersOtpGeneration = apiCall({
  inputEq: eqRegistersOtpGenerationInput,
  inputCodec: RegistersOtpGenerationInput,
  outputCodec: OtpGenerationOutput,
  path: ["packages", "mortgage", "registers", "otp"],
  errorCodec: RegistersOtpGenerationError,
});

const RegistersOtpVerifyInput = withCoApplicant(OtpVerifyInput);

export type RegistersOtpVerifyInput = t.TypeOf<typeof RegistersOtpVerifyInput>;

const eqRegistersOtpVerifyInput = eqWithCoApplicant(eqOtpVerifyInput);

export const registersOtpVerify = apiCall({
  inputEq: eqRegistersOtpVerifyInput,
  inputCodec: RegistersOtpVerifyInput,
  path: ["packages", "mortgage", "registers", "otp", "verify"],
  errorCodec: MTG3POtpVerifyError,
});

const RegisterStatus = t.type(
  {
    registers: CheckStatus,
    existingApplications: optionFromUndefined(CheckStatus),
  },
  "RegisterStatus"
);

export type RegisterStatus = t.TypeOf<typeof RegisterStatus>;

const RegisterStatusOutput = ApplicantsRecordC(RegisterStatus);

export type RegisterStatusOutput = t.TypeOf<typeof RegisterStatusOutput>;

export const registerStatus = apiCall({
  inputEq: eq.fromEquals(constFalse),
  inputCodec: t.void,
  path: ["packages", "mortgage", "creditBureau", "registerStatus"],
  outputCodec: RegisterStatusOutput,
});

const AmountLimit = t.type({ min: t.number, max: t.number });
export type AmountLimit = t.TypeOf<typeof AmountLimit>;

const baseCreditBureauResult = {
  internalInfoStatus: CreditBureauStatus,
  loanList: optionFromUndefined(LoanList),
  mtgAndClApprovedLoanAmountLimit: AmountLimit,
  mtgAndClCurrentBalanceLimit: AmountLimit,
  mtgAndClMonthlyInstallmentLimit: AmountLimit,
  ccAndOvdCurrentBalanceLimit: AmountLimit,
  ccAndOvdLimit: AmountLimit,
  applicationList: optionFromUndefined(ApplicationList),
};

export const CreditBureauResultsSK = t.type(
  {
    ...baseCreditBureauResult,
    srbiStatus: CreditBureauStatus,
    nrkiStatus: CreditBureauStatus,
    otherStatus: CreditBureauStatus,
    socialInsurance: StatusColor,
  },
  "CreditBureauResultsSK"
);
export type CreditBureauResultsSK = t.TypeOf<typeof CreditBureauResultsSK>;

export const CreditBureauResultsCZ = t.type(
  {
    ...baseCreditBureauResult,
    crifStatus: CreditBureauStatus,
    solusStatus: CreditBureauStatus,
    insolvencyRegisterStatus: CreditBureauStatus,
  },
  "CreditBureauResultsCZ"
);
export type CreditBureauResultsCZ = t.TypeOf<typeof CreditBureauResultsCZ>;

export const CreditBureauResults = t.union(
  [CreditBureauResultsCZ, CreditBureauResultsSK],
  "CreditBureauResults"
);

export type CreditBureauResults = t.TypeOf<typeof CreditBureauResults>;

const CreditBureauResultsOutPut = ApplicantsRecordC(
  optionFromUndefined(CreditBureauResults),
  option.none
);
export type CreditBureauResultsOutPut = t.TypeOf<
  typeof CreditBureauResultsOutPut
>;

export const creditBureauResults = apiCall({
  inputEq: eq.fromEquals(constFalse),
  inputCodec: t.void,
  path: ["packages", "mortgage", "creditBureau", "results"],
  outputCodec: CreditBureauResultsOutPut,
});

const ProceedWithBonitaOutput = t.type({
  hasSelectedOffer: t.boolean,
  applicationPhase: t.keyof({
    DIP: true,
    AC: true,
    PD: true,
    FD: true,
    REWORK: true,
    CHANGE_PROCESS: true,
  }),
  bonitaData: ApplicantsRecordC(BonitaApplicantState),
});

export const proceedWithBonita = apiCall({
  inputEq: eq.fromEquals(constFalse),
  inputCodec: t.void,
  path: ["packages", "mortgage", "bonita", "proceed"],
  outputCodec: ProceedWithBonitaOutput,
});

const GetBonitaOutput = t.type(
  {
    bonitaEvaluate: optionFromUndefined(BonitaResults, "Evaluate"),
    income: ApplicantsRecordC(
      optionFromUndefined(GetIncome, "Income"),
      option.none
    ),
    expenses: ApplicantsRecordC(
      optionFromUndefined(Expenses, "Expenses"),
      option.none
    ),
  },
  "GetBonitaOutput"
);

export type GetBonitaOutput = t.TypeOf<typeof GetBonitaOutput>;

export const getBonita = apiCall({
  inputEq: eq.fromEquals(constFalse),
  inputCodec: t.void,
  path: ["packages", "mortgage", "bonita", "get"],
  outputCodec: GetBonitaOutput,
});

export const saveBonitaIncome = apiCall({
  inputEq: eq.fromEquals(constFalse),
  inputCodec: withApplicantIndex(SaveIncome),
  path: ["packages", "mortgage", "bonita", "income", "save"],
  outputCodec: t.unknown,
});

export const editMainIncome = apiCall({
  inputEq: eq.fromEquals(constFalse),
  inputCodec: withApplicantIndex(IncomeFull),
  path: ["packages", "mortgage", "bonita", "mainIncome", "edit"],
  outputCodec: t.unknown,
});

export const addAdditionalIncome = apiCall({
  inputEq: eq.fromEquals(constFalse),
  inputCodec: withApplicantIndex(IncomeFull),
  path: ["packages", "mortgage", "bonita", "additionalIncome", "add"],
  outputCodec: t.unknown,
});

export const editAdditionalIncome = apiCall({
  inputEq: eq.fromEquals(constFalse),
  inputCodec: withApplicantIndex(AdditionalIncome),
  path: ["packages", "mortgage", "bonita", "additionalIncome", "edit"],
  outputCodec: t.unknown,
});

export const deleteAdditionalIncome = apiCall({
  inputEq: eq.fromEquals(constFalse),
  inputCodec: withApplicantIndex(DeleteAdditionalIncome),
  path: ["packages", "mortgage", "bonita", "additionalIncome", "delete"],
  outputCodec: t.unknown,
});

export const saveBonitaExpenses = apiCall({
  inputEq: eq.fromEquals(constFalse),
  inputCodec: withApplicantIndex(Expenses),
  path: ["packages", "mortgage", "bonita", "expenses", "save"],
  outputCodec: t.unknown,
});

export const getTotalLiabilities = apiCall({
  inputEq: eq.fromEquals(constTrue),
  inputCodec: withApplicantIndex(TotalLiabilitiesInput),
  path: ["packages", "mortgage", "bonita", "expenses", "totalLiabilities"],
  outputCodec: MoneyAmount,
});

const MinimumMonthlyExpenses = t.type(
  {
    minimumMonthlyExpenses: MoneyAmount,
  },
  "MinimumMonthlyExpenses"
);

export type MinimumMonthlyExpenses = t.TypeOf<typeof MinimumMonthlyExpenses>;

export const minimumMonthlyExpenses = apiCall({
  inputEq: eq.fromEquals(constFalse),
  inputCodec: withApplicantIndex(HouseholdInfo),
  path: ["packages", "mortgage", "bonita", "minimumMonthlyExpenses"],
  outputCodec: ApplicantsRecordC(MinimumMonthlyExpenses),
});

export const evaluateBonita = apiCall({
  inputEq: eq.fromEquals(constFalse),
  inputCodec: t.void,
  path: ["packages", "mortgage", "bonita", "evaluate"],
  outputCodec: t.unknown,
});

const OutputBonitaResults = optionFromUndefined(BonitaResults);

export const bonitaResults = apiCall({
  inputEq: eq.fromEquals(constFalse),
  inputCodec: t.void,
  path: ["packages", "mortgage", "bonita", "results"],
  outputCodec: OutputBonitaResults,
});

export const viewPropertyAndAppraisalDetails = apiCall({
  inputEq: eq.fromEquals(constFalse),
  inputCodec: t.void,
  path: ["packages", "mortgage", "property", "view"],
  outputCodec: PropertyAndAppraisalDetailsResponse,
});

const SaveAdditionalDetailsInput = withApplicantIndex(AdditionalDetails);
export type SaveAdditionalDetailsInput = t.TypeOf<
  typeof SaveAdditionalDetailsInput
>;

export const saveAdditionalDetails = apiCall({
  inputCodec: SaveAdditionalDetailsInput,
  inputEq: eq.fromEquals(constFalse),
  path: ["packages", "mortgage", "additionaldetails", "saveDetails"],
  outputCodec: t.unknown,
});

const GetAdditionalDetailsOutput = ApplicantsRecordC(
  optionFromUndefined(AdditionalDetails),
  option.none
);
export type GetAdditionalDetailsOutput = t.TypeOf<
  typeof GetAdditionalDetailsOutput
>;

export const getAdditionalDetails = apiCall({
  inputCodec: t.void,
  inputEq: eq.fromEquals(constFalse),
  path: ["packages", "mortgage", "additionaldetails", "getDetails"],
  outputCodec: GetAdditionalDetailsOutput,
});

const SubmitLegalDataInput = t.type({
  legalData: t.type({
    applicantIndex: NonNegativeInteger,
    answers: KYCAnswers,
  }),
});

export const submitLegalData = apiCall({
  inputCodec: SubmitLegalDataInput,
  inputEq: eq.fromEquals(constFalse),
  path: ["packages", "mortgage", "additionaldetails", "legaldata", "submit"],
  outputCodec: t.unknown,
});

const GetLegalDataOutput = t.type(
  {
    applicantIndex: t.string,
    answers: KYCAnswers,
  },
  "GetLegalDataOutput"
);

export type GetLegalData = t.TypeOf<typeof GetLegalDataOutput>;

export const getLegalData = apiCall({
  inputCodec: t.void,
  inputEq: eq.fromEquals(constTrue),
  path: ["packages", "mortgage", "additionaldetails", "getLegalData"],
  outputCodec: withFallback(
    optionFromUndefined(ApplicantsRecordC(GetLegalDataOutput)),
    option.none
  ),
});

const GetLegalDataOutput2 = t.type(
  {
    applicantIndex: t.string,
    answers: KYCAnswers2,
  },
  "GetLegalDataOutput2"
);

export type GetLegalData2 = t.TypeOf<typeof GetLegalDataOutput2>;

export const getLegalData2 = apiCall({
  inputCodec: t.void,
  inputEq: eq.fromEquals(constTrue),
  path: ["packages", "mortgage", "additionaldetails", "getLegalData"],
  outputCodec: withFallback(
    optionFromUndefined(ApplicantsRecordC(GetLegalDataOutput2)),
    option.none
  ),
});

const ProceedWithLegalDataOutput = t.type({
  hasLegalDataCompleted: t.boolean,
  legalDataRequired: t.boolean,
  computeBonitaBefore: t.boolean,
  secondCitizenshipRequired: t.boolean,
  incomeSource: optionFromUndefined(t.string),
});

export type ProceedWithLegalData = t.TypeOf<typeof ProceedWithLegalDataOutput>;

export const proceedWithLegalData = apiCall({
  inputCodec: t.void,
  inputEq: eq.fromEquals(constFalse),
  path: [
    "packages",
    "mortgage",
    "additionaldetails",
    "legaldata",
    "proceedWithLegalData",
  ],
  outputCodec: ApplicantsRecordC(ProceedWithLegalDataOutput),
});

const SaveCPIAnswersInput = withApplicantIndex(CPIAnswers);
export type SaveCPIAnswersInput = t.TypeOf<typeof SaveCPIAnswersInput>;

export const saveCPIAnswers = apiCall({
  inputCodec: SaveCPIAnswersInput,
  inputEq: eq.fromEquals(constFalse),
  path: ["packages", "mortgage", "additionaldetails", "saveCPI"],
  outputCodec: t.unknown,
});

const GetCPIDataOutput = t.type({
  insuredApplicantIndex: optionFromUndefined(t.string),
  cpiData: CPIData,
});
export type GetCPIDataOutput = t.TypeOf<typeof GetCPIDataOutput>;

export const getCPIData = apiCall({
  inputCodec: t.void,
  inputEq: eq.fromEquals(constFalse),
  path: ["packages", "mortgage", "additionaldetails", "getCPI"],
  outputCodec: GetCPIDataOutput,
});

const ProceedWithApplicantDetailsOutput = t.type({
  hasCPI: t.boolean,
  showCPI: t.boolean,
  proceedWithApplicantDetailsData: ApplicantsRecordC(ApplicantDetailsConfig),
});
export type ProceedWithApplicantDetailsOutput = t.TypeOf<
  typeof ProceedWithApplicantDetailsOutput
>;

export const proceedWithApplicantDetails = apiCall({
  inputCodec: t.void,
  inputEq: eq.fromEquals(constFalse),
  path: ["packages", "mortgage", "proceedWithApplicantDetails"],
  outputCodec: ProceedWithApplicantDetailsOutput,
});

export const HealthQuestionnaireState = t.keyof(
  {
    COMPLETED: true,
    CPI_NOT_FILLED: true,
    FILLED: true,
    NOT_NEEDED: true,
    RESULT_RECEIVED: true,
    TODO: true,
  },
  "HealthQuestionnaireState"
);

export type HealthQuestionnaireState = t.TypeOf<
  typeof HealthQuestionnaireState
>;

const ProceedWithACApplicantDetailsResponse = t.type(
  {
    hasMaritalStatus: t.boolean,
    maritalStatus: optionFromUndefined(MaritalStatus),
    hasBirthDate: t.boolean,
    hasEmploymentInfo: t.boolean,
    hasProbationInfo: t.boolean,
    hasCompletedCPI: t.boolean,
    hasCompletedPreScoring: t.boolean,
    hasMainIncomeTypeEmployed: t.boolean,
    hasCompletedClientProfile: t.boolean,
    hasCompletedLegalData: t.boolean,
    healthQuestionnaireState: HealthQuestionnaireState,
    isInsuredClient: t.boolean,
    sameHousehold: t.boolean,
  },
  "ProceedWithACApplicantDetailsResponse"
);

export const ProceedWithACApplicantDetails = t.type(
  {
    hasCPI: t.boolean,
    showCPI: t.boolean,
    proceedWithACApplicantDetailsData: ApplicantsRecordC(
      ProceedWithACApplicantDetailsResponse
    ),
  },
  "ProceedWithACApplicantDetails"
);

export type ProceedWithACApplicantDetails = t.TypeOf<
  typeof ProceedWithACApplicantDetails
>;

export const proceedWithACApplicantDetails = apiCall({
  inputCodec: t.void,
  inputEq: eq.fromEquals(constFalse),
  path: ["packages", "mortgage", "proceedWithACApplicantDetails"],
  outputCodec: ProceedWithACApplicantDetails,
});

export const editEmail = apiCall({
  inputCodec: t.type({
    applicantIndex: t.string,
    email: t.string,
  }),
  inputEq: eq.fromEquals(constFalse),
  path: ["packages", "mortgage", "client", "profile", "email", "edit"],
  outputCodec: t.unknown,
});

export const checkExistingClientMortgage = apiCall({
  path: ["packages", "mortgage", "client", "checkExisting"],
  inputCodec: CheckExistingClientInput,
  inputEq: eqCheckExistingClientInput,
  outputCodec: CheckExistingClientOutput,
  errorCodec: ApplicationLimitError,
});

export const NewMortgageCheckClientStatus = t.keyof({
  UNDERAGE_BRANCH: true,
  UNDERAGE_REMOTE: true,
  NOTES_CLIENT_COMPLETE: true,
  KYC_HIGH_RISK_BRANCH: true,
  KYC_HIGH_RISK_REMOTE: true,
  KYC_FOREIGN: true,
  SUCCESS: true,
  UPDATE_CONTACTS_BRANCH: true,
  UPDATE_CONTACTS_REMOTE: true,
  VERIFY_CONTACTS_BRANCH: true,
  VERIFY_CONTACTS_REMOTE: true,
  ADD_PASS_BRANCH: true,
  ADD_PASS_REMOTE: true,
  MTG_UPDATE_ID: true,
  MTG_UPDATE_ID_CONTACTS: true,
  UPDATE_ID_CONTACTS_VERIFY_CONTACTS: true,
  UPDATE_ID_CONTACTS_VERIFY_CONTACTS_SET_UP_PASS_BRANCH: true,
  UPDATE_ID_CONTACTS_VERIFY_CONTACTS_SET_UP_PASS_REMOTE: true,
  UPDATE_ID_VERIFY_CONTACTS: true,
  UPDATE_ID_VERIFY_CONTACTS_SET_UP_PASS_BRANCH: true,
  UPDATE_ID_VERIFY_CONTACTS_SET_UP_PASS_REMOTE: true,
  UPDATE_ID_SET_UP_PASS_BRANCH: true,
  UPDATE_ID_SET_UP_PASS_REMOTE: true,
});

export type NewMortgageCheckClientStatus = t.TypeOf<
  typeof NewMortgageCheckClientStatus
>;

export const CheckClientStatusOutput = t.type({
  status: NewMortgageCheckClientStatus,
  firstName: NonEmptyString,
  lastName: NonEmptyString,
  phoneNumber: NonEmptyString,
});
export type CheckClientStatusOutput = t.TypeOf<typeof CheckClientStatusOutput>;

export const checkClientStatus = apiCall({
  path: ["packages", "mortgage", "client", "checkStatus"],
  inputCodec: t.void,
  inputEq: eq.fromEquals(constFalse),
  outputCodec: CheckClientStatusOutput,
  errorCodec: GenericError,
});

const ProceedWithPreliminaryDecision = t.type(
  {
    initialDARDocId: NonEmptyString,
    gdprConsentId: optionFromUndefined(NonEmptyString),
    cpiApplicationDocId: optionFromUndefined(NonEmptyString),
    cpiMediationDocId: optionFromUndefined(NonEmptyString),
    cpiNoHq: optionFromUndefined(t.boolean),
  },
  "ProceedWithPreliminaryDecision"
);

export type ProceedWithPreliminaryDecision = t.TypeOf<
  typeof ProceedWithPreliminaryDecision
>;

export const proceedWithPreliminaryDecision = apiCall({
  path: [
    "packages",
    "mortgage",
    "application",
    "ac",
    "proceedWithPreliminaryDecision",
  ],
  inputCodec: t.void,
  inputEq: eq.fromEquals(constFalse),
  outputCodec: withFallback(
    optionFromUndefined(ApplicantsRecordC(ProceedWithPreliminaryDecision)),
    option.none
  ),
});

const ProceedWithPreliminaryDecisionStatus = t.type(
  {
    ready: t.boolean,
  },
  "ProceedWithPreliminaryDecisionStatus"
);

export type ProceedWithPreliminaryDecisionStatus = t.TypeOf<
  typeof ProceedWithPreliminaryDecisionStatus
>;

export const proceedWithPreliminaryDecisionStatus = apiCall({
  path: [
    "packages",
    "mortgage",
    "application",
    "ac",
    "proceedWithPreliminaryDecision",
    "status",
  ],
  inputCodec: t.void,
  inputEq: eq.fromEquals(constFalse),
  outputCodec: withFallback(
    optionFromUndefined(
      ApplicantsRecordC(ProceedWithPreliminaryDecisionStatus)
    ),
    option.none
  ),
});

export const proceedWithPreliminarySkipAuthorization = apiCall({
  path: [
    "packages",
    "mortgage",
    "application",
    "ac",
    "proceedWithPreliminarySkipAuthorization",
  ],
  inputCodec: t.void,
  inputEq: eq.fromEquals(constFalse),
  outputCodec: t.boolean,
});

const PreliminaryDecisionOtpGenerationInput = t.intersection([
  OtpGenerationInput,
  t.type({ applicantIndex: t.string }),
]);
export type PreliminaryDecisionOtpGenerationInput = t.TypeOf<
  typeof PreliminaryDecisionOtpGenerationInput
>;

const eqPreliminaryDecisionOtpGenerationInput = eq.getStructEq({
  phoneNumber: eq.eqString,
  applicantIndex: eq.eqString,
});

export const PreliminaryDecisionOtpGenerationError = t.union([
  OtpGenerationError,
  ApplicationLimitError,
  GenericError,
]);

export type PreliminaryDecisionOtpGenerationError = t.TypeOf<
  typeof PreliminaryDecisionOtpGenerationError
>;

export const preliminaryDecisionOtpGeneration = apiCall({
  inputEq: eqPreliminaryDecisionOtpGenerationInput,
  inputCodec: PreliminaryDecisionOtpGenerationInput,
  outputCodec: OtpGenerationOutput,
  path: [
    "packages",
    "mortgage",
    "application",
    "ac",
    "preliminaryDecision",
    "otp",
  ],
  errorCodec: PreliminaryDecisionOtpGenerationError,
});

export const preliminaryDecisionCanAuthorizeOTP = apiCall({
  inputEq: eq.fromEquals(constFalse),
  inputCodec: t.void,
  outputCodec: t.boolean,
  path: [
    "packages",
    "mortgage",
    "application",
    "ac",
    "preliminaryDecision",
    "canAuthorizeOTP",
  ],
});

const ConsentRequestInput = withApplicantIndex(
  t.type({
    accepted: t.boolean,
  })
);

type ConsentRequestInput = t.TypeOf<typeof ConsentRequestInput>;

export const setMarketingConsent = apiCall({
  inputCodec: ConsentRequestInput,
  inputEq: eq.fromEquals(constFalse),
  outputCodec: t.unknown,
  path: ["packages", "mortgage", "marketingConsent"],
});

export const setNonDebtsOrBankruptDeclaration = apiCall({
  inputCodec: ConsentRequestInput,
  inputEq: eq.fromEquals(constFalse),
  outputCodec: t.unknown,
  path: ["packages", "mortgage", "nonDebtsOrBankruptDeclaration"],
});

const PreliminaryDecisionOtpVerifyInput = withCoApplicant(OtpVerifyInput);

export type PreliminaryDecisionOtpVerifyInput = t.TypeOf<
  typeof PreliminaryDecisionOtpVerifyInput
>;

const eqPreliminaryDecisionOtpVerifyInput = eqWithCoApplicant(eqOtpVerifyInput);

export const preliminaryDecisionOtpVerify = apiCall({
  inputEq: eqPreliminaryDecisionOtpVerifyInput,
  inputCodec: PreliminaryDecisionOtpVerifyInput,
  path: [
    "packages",
    "mortgage",
    "application",
    "ac",
    "preliminaryDecision",
    "otp",
    "verify",
  ],
  errorCodec: MTG3POtpVerifyError,
});

const SubmitForPreliminaryDecisionInput = t.type(
  {
    comment: t.string,
  },
  "SubmitForPreliminaryDecisionInput"
);

export const submitForPreliminaryDecision = apiCall({
  path: [
    "packages",
    "mortgage",
    "application",
    "ac",
    "submitForPreliminaryDecision",
  ],
  inputCodec: SubmitForPreliminaryDecisionInput,
  inputEq: eq.fromEquals(constFalse),
  errorCodec: MTGSubmitApplicationError,
});

const GetBranchesOutput = t.type({
  branches: BranchesList,
});

export const getBranches = apiCall({
  path: ["packages", "mortgage", "assigned-user", "branches"],
  inputCodec: t.void,
  inputEq: eq.fromEquals(constFalse),
  outputCodec: GetBranchesOutput,
});

const GetBankersInput = t.type({
  branchCode: t.string,
});

const GetBankersOutput = t.type({
  bankers: BankersList,
});

const eqGetBankersInput = eq.getStructEq({
  branchCode: eq.eqString,
});

export const getBankers = apiCall({
  path: ["packages", "mortgage", "assigned-user", "bankers"],
  inputCodec: GetBankersInput,
  inputEq: eqGetBankersInput,
  outputCodec: GetBankersOutput,
});

const savePreferredBankerInput = t.type({
  branchCode: t.string,
  bankerId: t.string,
});

const eqSavePreferredBankerInput = eq.getStructEq({
  branchCode: eq.eqString,
  bankerId: eq.eqString,
});

export const savePreferredBanker = apiCall({
  path: ["packages", "mortgage", "assigned-user", "save"],
  inputCodec: savePreferredBankerInput,
  inputEq: eqSavePreferredBankerInput,
  outputCodec: t.unknown,
});

export const getAssignedUser = apiCall({
  path: ["packages", "mortgage", "assigned-user", "get"],
  inputCodec: t.void,
  inputEq: eq.fromEquals(constFalse),
  outputCodec: AssignedUser,
});

const handOverApplicationInput = t.type({
  handOverTo: UserType,
});

const eqHandOverApplicationInput = eq.getStructEq({
  handOverTo: eq.eqString,
});

export const handOverApplication = apiCall({
  path: ["packages", "mortgage", "assigned-user", "handover"],
  inputCodec: handOverApplicationInput,
  inputEq: eqHandOverApplicationInput,
  outputCodec: t.unknown,
});

export const GetBrokersInput = t.type(
  {
    partialEmail: t.string,
  },
  "GetBrokersInput"
);
export interface GetBrokersInput extends t.TypeOf<typeof GetBrokersInput> {}

export const eqGetBrokersInput: Eq<GetBrokersInput> = eq.getStructEq({
  partialEmail: eq.eqString,
});

export const GetBrokersOutput = t.array(t.string, "GetBrokersOutput");
export interface GetBrokersOutput extends t.TypeOf<typeof GetBrokersOutput> {}

export const getBrokers = apiCall({
  path: ["packages", "mortgage", "assigned-broker", "brokers"],
  inputCodec: GetBrokersInput,
  inputEq: eqGetBrokersInput,
  outputCodec: GetBrokersOutput,
});

export const getAssignedBrokerForBanker = apiCall({
  path: ["packages", "mortgage", "assigned-broker", "get"],
  inputCodec: t.void,
  inputEq: eq.fromEquals(constFalse),
  outputCodec: AssignedBrokerForBanker,
});

const saveAssignedBrokerForBankerInput = t.type({
  email: t.string,
});

const eqSaveAssignedBrokerForBankerInput = eq.getStructEq({
  email: eq.eqString,
});

const saveAssignedBrokerForBankerOutput = t.union([
  t.type({
    status: t.literal("VALID"),
  }),
  t.type({
    status: t.literal("INVALID"),
  }),
]);

export const saveAssignedBrokerForBanker = apiCall({
  path: ["packages", "mortgage", "assigned-broker", "save"],
  inputCodec: saveAssignedBrokerForBankerInput,
  inputEq: eqSaveAssignedBrokerForBankerInput,
  outputCodec: saveAssignedBrokerForBankerOutput,
});

export const removeAssignedBrokerForBanker = apiCall({
  path: ["packages", "mortgage", "assigned-broker", "remove"],
  inputCodec: t.void,
  inputEq: eq.fromEquals(constFalse),
  outputCodec: t.unknown,
});

const PDReceivedStatus = t.type({
  responseStatus: t.literal("RECEIVED"),
  applicationStatus: PreliminaryDecisionStatus,
  overLimitDecisionReasons: optionFromUndefined(t.array(LocalizedString)),
});

export type PDReceivedStatus = t.TypeOf<typeof PDReceivedStatus>;

export function isPDReceivedStatus(s: PDStatus): s is PDReceivedStatus {
  return s.responseStatus === "RECEIVED";
}

const PDNotReceivedStatus = t.type({
  responseStatus: t.literal("NOT_RECEIVED"),
});

type PDNotReceivedStatus = t.TypeOf<typeof PDNotReceivedStatus>;

const PDStatus = t.union(
  [PDNotReceivedStatus, PDReceivedStatus],
  "PreliminaryDecisionStatus"
);

export type PDStatus = t.TypeOf<typeof PDStatus>;

const PreliminaryDecisionStatusOutput = optionFromNullable(
  PDStatus,
  "PreliminaryDecisionStatus"
);

export type PreliminaryDecisionStatusOutput = t.TypeOf<
  typeof PreliminaryDecisionStatusOutput
>;

export const preliminaryDecisionStatus = apiCall({
  path: [
    "packages",
    "mortgage",
    "application",
    "ac",
    "preliminaryDecision",
    "status",
  ],
  inputCodec: t.void,
  inputEq: eq.fromEquals(constFalse),
  outputCodec: PreliminaryDecisionStatusOutput,
});

const EscalationSubmitInput = t.type(
  {
    comment: NonEmptyString,
  },
  "EscalationSubmitInput"
);

export const escalationSubmit = apiCall({
  path: ["packages", "mortgage", "escalation", "submit"],
  inputCodec: EscalationSubmitInput,
  inputEq: eq.fromEquals(constFalse),
});

const EscalationUploadEmailInput = t.type(
  {
    fileName: NonEmptyString,
    fileContent: FileContent,
  },
  "EscalationUploadEmailInput"
);

export const escalationUploadEmail = apiCall({
  path: ["packages", "mortgage", "escalation", "uploadEmail"],
  inputCodec: EscalationUploadEmailInput,
  inputEq: eq.fromEquals(constFalse),
});

const SubmitForFinalDecision = t.type({
  comment: t.string,
});

export const submitFinalDecision = apiCall({
  path: ["packages", "mortgage", "application", "ac", "submitFinalDecision"],
  inputCodec: SubmitForFinalDecision,
  inputEq: eq.fromEquals(constFalse),
  errorCodec: MTGSubmitApplicationError,
});

const FinalDecisionStatusOutputSome = t.union(
  [
    t.type({
      responseStatus: t.literal("NOT_RECEIVED"),
    }),
    t.type({
      responseStatus: t.literal("RECEIVED"),
      applicationStatus: FinalDecisionStatus,
    }),
  ],
  "FinalDecisionStatus"
);

export type FinalDecisionStatusOutputSome = t.TypeOf<
  typeof FinalDecisionStatusOutputSome
>;

const FinalDecisionStatusOutput = optionFromNullable(
  FinalDecisionStatusOutputSome,
  "FinalDecisionStatus"
);

export type FinalDecisionStatusOutput = t.TypeOf<
  typeof FinalDecisionStatusOutput
>;

export const finalDecisionStatus = apiCall({
  path: [
    "packages",
    "mortgage",
    "application",
    "ac",
    "finalDecision",
    "status",
  ],
  inputCodec: t.void,
  inputEq: eq.fromEquals(constFalse),
  outputCodec: FinalDecisionStatusOutput,
});

export const reworkActions = apiCall({
  path: ["packages", "mortgage", "rework", "actions"],
  inputCodec: t.void,
  inputEq: eq.fromEquals(constFalse),
  outputCodec: ReworkActions,
});

export const reworkData = apiCall({
  path: ["packages", "mortgage", "rework", "data"],
  inputCodec: t.void,
  inputEq: eq.fromEquals(constFalse),
  outputCodec: ReworkData,
});

export const preliminaryDecisionValidate = apiCall({
  path: [
    "packages",
    "mortgage",
    "application",
    "ac",
    "preliminaryDecision",
    "validate",
  ],
  inputCodec: t.void,
  inputEq: eq.fromEquals(constFalse),
});

const PreliminaryDecisionValidateStatusOutput = t.type(
  {
    status: t.keyof({
      PENDING: true,
      IS_VALID: true,
      NOT_COMPLETED: true,
      NO_BANKER_ASSIGNED: true,
      ERROR: true,
    }),
  },
  "PreliminaryDecisionValidateStatusOutput"
);
export type PreliminaryDecisionValidateStatusOutput = t.TypeOf<
  typeof PreliminaryDecisionValidateStatusOutput
>;

export const preliminaryDecisionValidateStatus = apiCall({
  path: [
    "packages",
    "mortgage",
    "application",
    "ac",
    "preliminaryDecision",
    "validationStatus",
  ],
  inputCodec: t.void,
  inputEq: eq.fromEquals(constFalse),
  outputCodec: PreliminaryDecisionValidateStatusOutput,
});

export const initiateChangeProcess = apiCall({
  path: ["packages", "mortgage", "application", "ac", "initiateChangeProcess"],
  inputCodec: t.void,
  inputEq: eq.fromEquals(constFalse),
  outputCodec: t.unknown,
});

export const termsAndConditions = apiCall({
  inputCodec: t.void,
  inputEq: eq.fromEquals(constFalse),
  outputCodec: TermsAndConditionsOutput,
  path: ["packages", "mortgage", "termsAndConditionsDocumentList"],
});

export const linkGenerationNow = apiCall({
  inputEq: eqLinkGenerationInput,
  path: ["packages", "mortgage", "email", "activationLink", "now"],
  inputCodec: LinkGenerationInput,
  outputCodec: LinkGenerationOutput,
  errorCodec: LinkGenerationError,
});

export const generateIdentificationOtp = apiCall({
  path: ["packages", "mortgage", "client", "identification", "otp"],
  inputCodec: OtpGenerationInputBase,
  inputEq: eqOtpGenerationInputBase,
  outputCodec: OTPGenerationOutputBase,
  errorCodec: t.union([OtpGenerationErrorBase, GenericError]),
});

const eqWithCoapplicantOtpVerifyInput: Eq<OtpVerifyInputBase> = eqWithCoApplicant(
  eq.getStructEq({
    otp: eq.eqString,
  })
);

export const verifyIdentificationOtp = apiCall({
  inputEq: eqWithCoapplicantOtpVerifyInput,
  path: ["packages", "mortgage", "client", "identification", "otp", "verify"],
  inputCodec: OtpVerifyInputBase,
  errorCodec: OtpVerifyErrorBase,
});
