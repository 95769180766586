import {
  Banner,
  ContentRow,
  Loader,
  SMSAuthorization,
  useIsMobileLayout,
  OTP,
  PositiveInteger,
  LocalizedString,
  useForm,
} from "design-system";
import { option, taskEither } from "fp-ts";
import { constant, constVoid, pipe } from "fp-ts/function";
import { ReaderTaskEither } from "fp-ts/ReaderTaskEither";
import { Option } from "fp-ts/Option";
import { GenericError } from "../globalDomain";
import * as otpAPI from "../OTP/domain";
import { TaskEither } from "fp-ts/TaskEither";
import { useFormatMessage } from "../intl";
import { ComponentProps, Dispatch, useEffect } from "react";
import {
  foldState,
  State,
  Action,
  reducerConfig,
  InsertingOtpState,
} from "./state";
import { useSnackBar } from "../useSnackbar";
import { ApplicationLimitError } from "../MortgageDashboard/domain";
import {
  useChildSharedReducer,
  useParentSharedReducer,
} from "../BranchExperience/useSharedReducer";
import { getGenerateOTP } from "./getGenerateOTP";
import { useValidators } from "../Common/useValidators";
import { usePortalStatusContext } from "../PortalStatusContext";

type Props = {
  phoneNumber: string;
  requestNotice: Option<LocalizedString>;
  otpRequestButtonLabel: LocalizedString;
  otpSubmitButtonLabel: LocalizedString;
  otpTitleLabel: LocalizedString;
  onProcessStart: TaskEither<unknown, unknown>;
  onSuccess: TaskEither<unknown, unknown>;
  onFailure: () => unknown;
  onGenerateOTP: ReaderTaskEither<
    otpAPI.OtpGenerationInput,
    otpAPI.OtpGenerationError | GenericError | ApplicationLimitError,
    otpAPI.OtpGenerationOutput & { transactionId: Option<LocalizedString> }
  >;
  onVerifyOTP: ReaderTaskEither<
    otpAPI.OtpVerifyInput,
    otpAPI.OtpVerifyError | GenericError,
    unknown
  >;
  length: PositiveInteger;
  disabled?: boolean;
  allowResendOTP: boolean;
} & (
  | {
      afterLoginState?: never;
      processStarted: boolean;
    }
  | {
      afterLoginState: InsertingOtpState;
      processStarted?: never;
    }
);

function OTPAuthorizationInternal(
  props: Props & {
    state: State;
    dispatch: Dispatch<Action>;
  }
) {
  const { state, dispatch } = props;
  const formatMessage = useFormatMessage();
  const isMobileLayout = useIsMobileLayout();
  const { showSnackbar } = useSnackBar();
  const { validOTP } = useValidators();
  const { portalBlocked } = usePortalStatusContext();

  useEffect(() => {
    if (props.afterLoginState == undefined && props.processStarted) {
      generateOTP();
    }
  }, [props.processStarted]);

  type OTPError =
    | otpAPI.OtpGenerationError
    | otpAPI.OtpVerifyError
    | GenericError;

  function errorToIssue(
    error: OTPError
  ): {
    type: Extract<ComponentProps<typeof Banner>["type"], "warning" | "error">;
    content: ComponentProps<typeof Banner>["content"];
    title: Option<LocalizedString>;
    actions: ComponentProps<typeof Banner>["actions"];
  } {
    const message = (() => {
      switch (error.id) {
        case "GenericError":
        case "InvalidFlowId":
          return formatMessage("Identification.otp.genericError");
        case "InvalidOTP":
          return formatMessage("Identification.otp.invalidCode", {
            remainingAttempts: error.attemptsLeft,
          });
        case "OTPExpired":
          return formatMessage("Identification.otp.expiredCode");
        case "MaxOtpRequestsReached":
          return formatMessage("OTPAuthorizationParent.otp.maxOTPRequests");
        case "MaxOtpAttemptsReached":
          return formatMessage("OTPAuthorizationParent.otp.maxOTPAttempts");
      }
    })();
    return {
      type: "error",
      content: message,
      title: option.none,
      actions: option.none,
    };
  }

  const generateOTP = getGenerateOTP({
    ...props,
    onStart: () => dispatch({ type: "SetOtpPending", pending: true }),
    onEnd: () => dispatch({ type: "SetOtpPending", pending: false }),
    onError: error => dispatch({ type: "SetGenerateOtpError", error }),
    onSuccess: (transactionId, remainingOtpRequests) =>
      dispatch({
        type: "HandleReceiveOTP",
        transactionId,
        remainingOtpRequests,
      }),
  });

  const onSubmit = (otp: OTP<PositiveInteger>) =>
    taskEither.bracket(
      taskEither.rightIO(() =>
        dispatch({ type: "SetOtpPending", pending: true })
      ),
      () =>
        pipe(
          props.onVerifyOTP({ otp }),
          taskEither.orElse(error =>
            taskEither.leftIO(() => {
              if (error.id === "InvalidOTP" || error.id === "OTPExpired") {
                handleReset();
              }
              if (error.id === "MaxOtpAttemptsReached") {
                return props.onFailure();
              }
              if (
                state.type !== "initial" &&
                state.type !== "generateOtpError" &&
                error.id === "InvalidOTP" &&
                error.attemptsLeft <= 0
              ) {
                if (state.remainingOtpRequests <= 0) {
                  return props.onFailure();
                } else {
                  dispatch({ type: "HandleLastAttemptFailed" });
                }
              } else {
                dispatch({ type: "SetVerifyOtpError", error });
              }
            })
          ),
          taskEither.chain(() => props.onSuccess)
        ),
      () =>
        taskEither.rightIO(() =>
          dispatch({ type: "SetOtpPending", pending: false })
        )
    );

  const { handleSubmit, fieldProps, handleReset } = useForm(
    {
      initialValues: {
        pin: "",
      },
      fieldValidators: constant({ pin: validOTP(props.length) }),
    },
    { onSubmit: ({ pin }) => onSubmit(pin) }
  );

  const smsAuthProps = {
    title: props.otpTitleLabel,
    phoneNumber: {
      label: formatMessage("Identification.otp.verifiedPhoneNumber"),
      value: props.phoneNumber,
    },
    message: formatMessage("Identification.otp.message"),
    sendOtp: {
      action: props.onProcessStart,
      label: props.otpRequestButtonLabel,
    },
    insertOtpTitle: formatMessage("Identification.otp.insertNumericCode"),
    insertOtpSubtitle: props.requestNotice,
    submitLabel: props.otpSubmitButtonLabel,
    onSubmit: () => pipe(handleSubmit, taskEither.map(constVoid)),
    pinInput: fieldProps("pin"),
    otpLength: props.length,
    noCodeLabel: formatMessage("Identification.otp.notReceivingSMS"),
    resendOtp: pipe(
      {
        action: pipe(
          generateOTP,
          taskEither.chain(() =>
            taskEither.fromIO(() => {
              showSnackbar({
                type: "success",
                action: option.none,
                message: formatMessage("Identification.otp.newCodeSent"),
              });
            })
          )
        ),
        label: formatMessage("Identification.otp.resendCode"),
      },
      option.fromPredicate(() => props.allowResendOTP)
    ),
  } as const;

  const smsAuthorization = (() => {
    if (props.afterLoginState == undefined && !props.processStarted) {
      return (
        <SMSAuthorization
          {...smsAuthProps}
          status="initial"
          issue={option.none}
          disabled={props.disabled || portalBlocked}
        />
      );
    }

    return pipe(
      state,
      foldState({
        whenInitial: () => (
          <SMSAuthorization
            {...smsAuthProps}
            status="initial"
            issue={option.none}
            disabled={props.disabled || portalBlocked}
          />
        ),
        whenGenerateOtpError: s => (
          <SMSAuthorization
            {...smsAuthProps}
            status="initial"
            issue={option.some(errorToIssue(s.error))}
            disabled={props.disabled || portalBlocked}
          />
        ),
        whenInsertingOtp: s => (
          <SMSAuthorization
            {...smsAuthProps}
            status="insertOTP"
            transactionId={s.transactionId}
            issue={pipe(
              {
                type: "warning",
                title: option.none,
                content: formatMessage("Identification.otp.lastRequestWarning"),
                actions: option.none,
              } as const,
              option.fromPredicate(
                () => s.remainingOtpRequests === 0 && props.allowResendOTP
              )
            )}
            disabled={s.pending || portalBlocked}
          />
        ),
        whenVerifyOtpError: s => (
          <SMSAuthorization
            {...smsAuthProps}
            status="insertOTP"
            transactionId={s.transactionId}
            issue={option.some(errorToIssue(s.error))}
            disabled={s.pending || portalBlocked}
          />
        ),
        whenRegenerateOtpError: s => (
          <SMSAuthorization
            {...smsAuthProps}
            status="insertOTP"
            transactionId={s.transactionId}
            issue={option.some(errorToIssue(s.error))}
            disabled={s.pending || portalBlocked}
          />
        ),
        whenNoMoreAttempts: s => (
          <SMSAuthorization
            {...smsAuthProps}
            status="insertOTP"
            transactionId={s.transactionId}
            issue={option.some({
              type: "error",
              content: formatMessage("Identification.otp.codeWrongResendCode"),
              actions: option.some([
                {
                  variant: "secondary",
                  label: formatMessage("Identification.otp.resendCode"),
                  action: () => generateOTP(),
                },
              ]),
              title: option.none,
            })}
            disabled
          />
        ),
      })
    );
  })();

  if (isMobileLayout) {
    return <ContentRow type="lateral-margins">{smsAuthorization}</ContentRow>;
  } else {
    return smsAuthorization;
  }
}

export function OTPAuthorization(props: Props) {
  const [state, dispatch] = useParentSharedReducer(
    reducerConfig,
    props.afterLoginState || {
      type: "initial",
    }
  );

  return (
    <OTPAuthorizationInternal {...props} state={state} dispatch={dispatch} />
  );
}

export function OTPAuthorizationChild(props: Props) {
  const [state, dispatch] = useChildSharedReducer(reducerConfig);

  return pipe(
    state,
    option.fold(
      () => <Loader />,
      state => (
        <OTPAuthorizationInternal
          {...props}
          state={state}
          dispatch={dispatch}
        />
      )
    )
  );
}
