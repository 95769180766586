import { NonNegativeInteger, LocalizedString } from "design-system";
import { GenericError, optionFromUndefined } from "../globalDomain";
import * as otpAPI from "../OTP/domain";
import * as t from "io-ts";
import { sharedReducerConfig } from "../BranchExperience/useSharedReducer";

const InitialState = t.type({
  type: t.literal("initial"),
});
type InitialState = t.TypeOf<typeof InitialState>;

const InsertingOtpState = t.type({
  type: t.literal("insertingOtp"),
  transactionId: optionFromUndefined(LocalizedString),
  remainingOtpRequests: NonNegativeInteger,
  pending: t.boolean,
});
export type InsertingOtpState = t.TypeOf<typeof InsertingOtpState>;

const GenerateOtpErrorState = t.type({
  type: t.literal("generateOtpError"),
  error: t.union([
    otpAPI.InvalidFlowIdError,
    otpAPI.OtpGenerationError,
    GenericError,
  ]),
});
type GenerateOtpErrorState = t.TypeOf<typeof GenerateOtpErrorState>;

const RegenerateOtpErrorState = t.type({
  type: t.literal("regenerateOtpError"),
  error: t.union([
    otpAPI.InvalidFlowIdError,
    otpAPI.OtpGenerationError,
    GenericError,
  ]),
  transactionId: optionFromUndefined(LocalizedString),
  remainingOtpRequests: NonNegativeInteger,
  pending: t.boolean,
});
type RegenerateOtpErrorState = t.TypeOf<typeof RegenerateOtpErrorState>;

const VerifyOtpErrorState = t.type({
  type: t.literal("verifyOtpError"),
  error: t.union([
    otpAPI.InvalidFlowIdError,
    otpAPI.OTPExpiredError,
    otpAPI.InvalidOTPError,
    GenericError,
  ]),
  transactionId: optionFromUndefined(LocalizedString),
  remainingOtpRequests: NonNegativeInteger,
  pending: t.boolean,
});
type VerifyOtpErrorState = t.TypeOf<typeof VerifyOtpErrorState>;

const NoMoreAttemptsState = t.type({
  type: t.literal("noMoreAttempts"),
  transactionId: optionFromUndefined(LocalizedString),
  remainingOtpRequests: NonNegativeInteger,
});
type NoMoreAttemptsState = t.TypeOf<typeof NoMoreAttemptsState>;

export const State = t.union([
  InitialState,
  InsertingOtpState,
  VerifyOtpErrorState,
  GenerateOtpErrorState,
  RegenerateOtpErrorState,
  NoMoreAttemptsState,
]);
export type State = t.TypeOf<typeof State>;

export function foldState<A>(f: {
  whenInitial: (state: InitialState) => A;
  whenInsertingOtp: (state: InsertingOtpState) => A;
  whenVerifyOtpError: (state: VerifyOtpErrorState) => A;
  whenGenerateOtpError: (state: GenerateOtpErrorState) => A;
  whenRegenerateOtpError: (state: RegenerateOtpErrorState) => A;
  whenNoMoreAttempts: (state: NoMoreAttemptsState) => A;
}): (state: State) => A {
  return (state: State) => {
    switch (state.type) {
      case "initial":
        return f.whenInitial(state);
      case "insertingOtp":
        return f.whenInsertingOtp(state);
      case "verifyOtpError":
        return f.whenVerifyOtpError(state);
      case "generateOtpError":
        return f.whenGenerateOtpError(state);
      case "regenerateOtpError":
        return f.whenRegenerateOtpError(state);
      case "noMoreAttempts":
        return f.whenNoMoreAttempts(state);
    }
  };
}

const SetGenerateOtpErrorAction = t.type({
  type: t.literal("SetGenerateOtpError"),
  error: t.union([
    otpAPI.InvalidFlowIdError,
    otpAPI.OtpGenerationError,
    GenericError,
  ]),
});

const SetVerifyOtpErrorAction = t.type({
  type: t.literal("SetVerifyOtpError"),
  error: t.union([
    otpAPI.InvalidFlowIdError,
    otpAPI.OTPExpiredError,
    otpAPI.InvalidOTPError,
    GenericError,
  ]),
});

const HandleReceiveOTPAction = t.type({
  type: t.literal("HandleReceiveOTP"),
  transactionId: optionFromUndefined(LocalizedString),
  remainingOtpRequests: NonNegativeInteger,
});

const HandleLastAttemptFailedAction = t.type({
  type: t.literal("HandleLastAttemptFailed"),
});

const SetOtpPendingAction = t.type({
  type: t.literal("SetOtpPending"),
  pending: t.boolean,
});

const Action = t.union([
  SetGenerateOtpErrorAction,
  SetVerifyOtpErrorAction,
  HandleLastAttemptFailedAction,
  HandleReceiveOTPAction,
  SetOtpPendingAction,
]);
export type Action = t.TypeOf<typeof Action>;

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case "SetVerifyOtpError":
      if (
        state.type === "verifyOtpError" ||
        state.type === "insertingOtp" ||
        state.type === "regenerateOtpError"
      ) {
        return {
          type: "verifyOtpError",
          error: action.error,
          transactionId: state.transactionId,
          remainingOtpRequests: state.remainingOtpRequests,
          pending: state.pending,
        };
      }
      return state;

    case "SetGenerateOtpError":
      if (state.type === "initial" || state.type === "generateOtpError") {
        return {
          type: "generateOtpError",
          error: action.error,
        };
      } else if (state.type !== "noMoreAttempts") {
        return {
          type: "regenerateOtpError",
          error: action.error,
          remainingOtpRequests: state.remainingOtpRequests,
          transactionId: state.transactionId,
          pending: state.pending,
        };
      }
      return state;

    case "HandleLastAttemptFailed":
      if (state.type !== "initial" && state.type !== "generateOtpError") {
        return {
          type: "noMoreAttempts",
          transactionId: state.transactionId,
          remainingOtpRequests: state.remainingOtpRequests,
        };
      }
      return state;

    case "HandleReceiveOTP":
      return {
        type: "insertingOtp",
        transactionId: action.transactionId,
        remainingOtpRequests: action.remainingOtpRequests,
        pending: false,
      };

    case "SetOtpPending":
      if (
        state.type !== "initial" &&
        state.type !== "generateOtpError" &&
        state.type !== "noMoreAttempts"
      ) {
        return {
          ...state,
          pending: action.pending,
        };
      }
      return state;
  }
}

export const reducerConfig = sharedReducerConfig(
  "OTPAuthorization",
  State,
  Action,
  reducer
);
