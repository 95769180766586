import {
  fieldIssues,
  InlineMessage,
  Issues,
  LocalizedString,
} from "design-system";
import { option } from "fp-ts";
import { pipe } from "fp-ts/function";
import { Option } from "fp-ts/Option";
import { ComponentProps } from "react";
import { useFormatMessage } from "../intl";

export type Feedback = ReturnType<
  ReturnType<typeof useFormatReworkData>["formatFeedback"]
>;

export function useFormatReworkData<A extends Record<string, Option<unknown>>>(
  reworkData: Option<A>
) {
  const formatMessage = useFormatMessage();

  type MessageType = ComponentProps<typeof InlineMessage>["type"];

  function formatFeedback(
    fieldName: keyof A
  ): { type: MessageType; message: LocalizedString } | undefined {
    return pipe(
      reworkData,
      option.chain(data => data[fieldName]),
      option.map(() => ({
        type: "warning" as const,
        message: formatMessage(
          "Mortgage.Dashboard.ReworkData.fieldChangedMessage"
        ),
      })),
      option.toUndefined
    );
  }

  // do not override issues of the input
  function formatIssues(
    fieldName: keyof A,
    existingIssues: Option<Issues>
  ): Option<Issues> {
    return pipe(
      existingIssues,
      option.alt<Issues>(() =>
        pipe(
          reworkData,
          option.chain(data => data[fieldName]),
          option.map(() =>
            fieldIssues.warnings([
              formatMessage(
                "Mortgage.Dashboard.ReworkData.fieldChangedMessage"
              ),
            ])
          )
        )
      )
    );
  }

  return { formatFeedback, formatIssues };
}
