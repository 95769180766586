import { apiCall } from "../../../APICall";
import * as t from "io-ts";
import {
  ConsumerLoanOrMortgage,
  ConsumerLoanOrMortgageAdd,
  withApplicantIndex,
  Overdraft,
  OverdraftAdd,
  CreditCard,
  CreditCardAdd,
  Provider,
} from "../../domain";
import { eq } from "fp-ts";
import { constFalse, constTrue } from "fp-ts/function";
import { NonEmptyString } from "io-ts-types/lib/NonEmptyString";
import {
  MoneyAmount,
  MonthYear,
  optionFromUndefined,
} from "../../../globalDomain";

const DeleteLiabilityRecordInput = withApplicantIndex(
  t.type({ recordId: NonEmptyString })
);

export const deleteLiabilityRecord = apiCall({
  path: ["packages", "mortgage", "liabilities", "delete"],
  inputCodec: DeleteLiabilityRecordInput,
  inputEq: eq.fromEquals(constFalse),
});

const CancelWithoutSavingInput = withApplicantIndex(t.type({}));

export const cancelWithoutSaving = apiCall({
  path: ["packages", "mortgage", "liabilities", "cancelWithoutSaving"],
  inputCodec: CancelWithoutSavingInput,
  inputEq: eq.fromEquals(constFalse),
});

export const saveAndUpdate = apiCall({
  path: ["packages", "mortgage", "liabilities", "saveAndUpdate"],
  inputCodec: t.void,
  inputEq: eq.fromEquals(constFalse),
});

const AddConsumerLoanOrMortgageInput = withApplicantIndex(
  ConsumerLoanOrMortgageAdd
);

export const addConsumerLoanOrMortgage = apiCall({
  path: ["packages", "mortgage", "liabilities", "addConsumerLoanOrMortgage"],
  inputCodec: AddConsumerLoanOrMortgageInput,
  inputEq: eq.fromEquals(constFalse),
  outputCodec: ConsumerLoanOrMortgage,
});

const ConsumerLoanOrMortgageEdit = t.type(
  {
    ...ConsumerLoanOrMortgageAdd.props,
    recordId: NonEmptyString,
  },
  "ConsumerLoanOrMortgageEdit"
);

const EditConsumerLoanOrMortgageInput = withApplicantIndex(
  ConsumerLoanOrMortgageEdit
);

export const editConsumerLoanOrMortgage = apiCall({
  path: ["packages", "mortgage", "liabilities", "editConsumerLoanOrMortgage"],
  inputCodec: EditConsumerLoanOrMortgageInput,
  outputCodec: ConsumerLoanOrMortgage,
  inputEq: eq.fromEquals(constFalse),
});

const AddOverdraftInput = withApplicantIndex(OverdraftAdd);

export const addOverdraft = apiCall({
  path: ["packages", "mortgage", "liabilities", "addOverdraft"],
  inputCodec: AddOverdraftInput,
  inputEq: eq.fromEquals(constFalse),
  outputCodec: Overdraft,
});

const OverdraftEdit = t.type(
  {
    ...OverdraftAdd.props,
    recordId: NonEmptyString,
  },
  "OverdraftEdit"
);

const EditOverdraftInput = withApplicantIndex(OverdraftEdit);

export const editOverdraft = apiCall({
  path: ["packages", "mortgage", "liabilities", "editOverdraft"],
  inputCodec: EditOverdraftInput,
  inputEq: eq.fromEquals(constFalse),
  outputCodec: Overdraft,
});

const AddCreditCardInput = withApplicantIndex(CreditCardAdd);

export const addCreditCard = apiCall({
  path: ["packages", "mortgage", "liabilities", "addCreditCard"],
  inputCodec: AddCreditCardInput,
  inputEq: eq.fromEquals(constFalse),
  outputCodec: CreditCard,
});

const CreditCardEdit = t.type(
  {
    ...CreditCardAdd.props,
    recordId: NonEmptyString,
  },
  "CreditCardEdit"
);

const EditCreditCardInput = withApplicantIndex(CreditCardEdit);

export const editCreditCard = apiCall({
  path: ["packages", "mortgage", "liabilities", "editCreditCard"],
  inputCodec: EditCreditCardInput,
  inputEq: eq.fromEquals(constFalse),
  outputCodec: CreditCard,
});

const GetProvidersOutput = t.type({
  loanProviderList: t.array(Provider, "Providers"),
});

export const getProviders = apiCall({
  path: ["packages", "mortgage", "liabilities", "providers"],
  inputCodec: t.void,
  inputEq: eq.fromEquals(constFalse),
  outputCodec: GetProvidersOutput,
});

export const start = apiCall({
  path: ["packages", "mortgage", "liabilities", "start"],
  inputCodec: t.void,
  inputEq: eq.fromEquals(constTrue),
});

const ExistingApplicationEdit = t.type(
  {
    recordId: NonEmptyString,
    provider: optionFromUndefined(NonEmptyString),
    monthlyInstallment: optionFromUndefined(MoneyAmount),
    overdraftLimit: optionFromUndefined(MoneyAmount),
    creditCardLimit: optionFromUndefined(MoneyAmount),
    loanAmount: optionFromUndefined(MoneyAmount),
    dateOfApplication: optionFromUndefined(MonthYear),
    status: t.boolean,
  },
  "ExistingApplicationEdit"
);

const EditExistingApplicationInput = withApplicantIndex(
  ExistingApplicationEdit
);

export const editExistingApplication = apiCall({
  path: ["packages", "mortgage", "liabilities", "editExistingApplication"],
  inputCodec: EditExistingApplicationInput,
  inputEq: eq.fromEquals(constFalse),
});

const saveExistingApplicationsInput = t.type({
  applicantIndex: t.string,
});

const eqSaveExistingApplicationsInput = eq.getStructEq({
  applicantIndex: eq.eqString,
});

export const saveExistingApplications = apiCall({
  path: ["packages", "mortgage", "liabilities", "saveExistingApplications"],
  inputCodec: saveExistingApplicationsInput,
  inputEq: eqSaveExistingApplicationsInput,
});
