import { LocalizedString } from "design-system";
import { CreditType } from "./domain";
import { useFormatMessage } from "../intl";

export function useFormatCreditType() {
  const formatMessage = useFormatMessage();

  return function formatCreditType(creditType: CreditType): LocalizedString {
    switch (creditType) {
      case "CONSUMER_LOAN":
        return formatMessage(
          "Liabilities.AddConsumerLoanOrMortgage.creditType.consumerLoan"
        );
      case "MORTGAGE_LOAN":
        return formatMessage(
          "Liabilities.AddConsumerLoanOrMortgage.creditType.mortgageLoan"
        );
    }
  };
}
