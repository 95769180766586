import { NonNegativeInteger, PositiveInteger } from "design-system";
import { option } from "fp-ts";
import { flow, Lazy } from "fp-ts/function";
import { Option } from "fp-ts/Option";
import { Reader } from "fp-ts/Reader";
import { NonEmptyString } from "io-ts-types/lib/NonEmptyString";
import { CoApplicantInput, unsafeNonNegativeInteger } from "../globalDomain";
import {
  ApplicationChecksOutput,
  ApplicationPhase,
  DipApplicationChecks,
  PostDipApplicationChecks,
  RegisterStatusOutput,
} from "./api";
import { ErrorProfileType } from "./domain";
import { ApplicantInfo } from "./PreliminaryDecision/Sending";

type MainApplicant = {
  type: "MainApplicant";
  index: NonNegativeInteger;
};

type CoApplicant = {
  type: "CoApplicant";
  index: PositiveInteger;
};

type GeneralApplicant = {
  type: "General";
  index: PositiveInteger;
};

export type SelectedApplicant = MainApplicant | CoApplicant | GeneralApplicant;

export const isApplicant = (a: SelectedApplicant) =>
  a.type === "MainApplicant" || a.type === "CoApplicant";

export const selectedMainApplicant: SelectedApplicant = {
  type: "MainApplicant",
  index: unsafeNonNegativeInteger(0),
};

export function isMainApplicant(a: SelectedApplicant): a is MainApplicant {
  return a.type === "MainApplicant";
}

export const selectedCoApplicant = (
  index: PositiveInteger
): SelectedApplicant => ({
  type: "CoApplicant",
  index,
});

export const getSelectedApplicant = (
  index: NonNegativeInteger
): SelectedApplicant =>
  index === 0
    ? selectedMainApplicant
    : {
        type: "CoApplicant",
        index: index as PositiveInteger,
      };

export const selectedGeneral = (index: PositiveInteger): SelectedApplicant => ({
  type: "General",
  index,
});

export type KoUploadIdError =
  | "SUSPECTED_FRAUD"
  | "MaxAttemptsReached"
  | ErrorProfileType
  | boolean;

export type Section =
  | { id: "overview"; showApplicationStatus: boolean }
  | { id: "clientProfile"; selectedApplicant: SelectedApplicant }
  | { id: "offer" }
  | { id: "bonita"; selectedApplicant: SelectedApplicant }
  | {
      id: "registers";
      selectedApplicant: SelectedApplicant;
      fromBonita: boolean;
    }
  | { id: "additionalDetails"; selectedApplicant: SelectedApplicant }
  | { id: "healthQuestionnaire"; selectedApplicant: SelectedApplicant }
  | {
      id: "kycQuestionnaire";
      selectedApplicant: SelectedApplicant;
      secondCitizenshipRequired: boolean;
    }
  | ({
      id: "phoneVerification";
      initialPhoneNumber: Option<NonEmptyString>;
    } & CoApplicantInput)
  | ({
      id: "emailVerification";
      initialEmail: Option<NonEmptyString>;
    } & CoApplicantInput)
  | ({ id: "uploadId" } & CoApplicantInput)
  | {
      id: "koUploadId";
      type: KoUploadIdError;
      hasCoApplicant?: boolean;
    }
  | { id: "purposeAndFinancing" }
  | { id: "propertyAndAppraisal"; selectedApplicant: SelectedApplicant }
  | { id: "documents"; selectedApplicant: SelectedApplicant }
  | { id: "communication" }
  | {
      id: "sendingPreliminaryDecision";
      applicant: ApplicantInfo;
      coApplicant: Option<ApplicantInfo>;
      coApplicantIndex: Option<NonNegativeInteger>;
      comment: Option<string>;
    }
  | { id: "decisionEscalation" }
  | { id: "maxNumberChangesReached" };

export type OnChangeSection = (
  section: Section,
  refreshChecks?: boolean
) => void;

export const checkIfCoapplicantCBCalled = (
  registerStatus: RegisterStatusOutput
) => registerStatus.length > 1 && registerStatus[1].data.registers === "ToDo";

export const liabilitiesAmountLimits = {
  min: 0,
  max: 999_999_999,
};

export function foldApplicationPhase<A>(
  pattern: { [k in ApplicationPhase]: Lazy<A> }
): Reader<ApplicationPhase, A> {
  return applicationPhase => pattern[applicationPhase]();
}

export function foldChecks<A>(match: {
  whenDip: (checks: DipApplicationChecks) => A;
  whenPostDip: (tab: PostDipApplicationChecks) => A;
}): Reader<ApplicationChecksOutput, A> {
  return checks =>
    checks.applicationPhase === "DIP"
      ? match.whenDip(checks)
      : match.whenPostDip(checks);
}

export const callCB = option.exists<ApplicationChecksOutput>(
  ({ controlCheck }) => controlCheck
);

export function isDIPPhase(phase: ApplicationPhase): boolean {
  return phase === "DIP";
}

export function isReworkPhase(phase: ApplicationPhase): boolean {
  return phase === "REWORK";
}

export const isNotUndefined = flow(option.fromNullable, option.isSome);
