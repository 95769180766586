import { option } from "fp-ts";
import { constFalse, pipe } from "fp-ts/function";
import { Option } from "fp-ts/Option";
import { MoneyAmount } from "../../../globalDomain";

export const toFalse = (booleanOption: Option<boolean>) =>
  pipe(booleanOption, option.getOrElse(constFalse));

export const resolveAmount = (moneyAmount: Option<MoneyAmount>) =>
  pipe(
    moneyAmount,
    option.map(({ amount }) => amount)
  );

export const checkRequiredFieldsProvided = <
  T extends { [index: string]: Option<unknown> }
>(
  values: T,
  requiredFields: { [K in keyof T]: boolean }
) =>
  Object.entries(values)
    .filter(
      ([field]) =>
        requiredFields.hasOwnProperty(field) &&
        requiredFields[field as keyof typeof requiredFields]
    )
    .map(([_, value]) => value)
    .reduce(
      (result, value: Option<unknown>) => result && option.isSome(value),
      true
    );
