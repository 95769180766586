import {
  Banner,
  Body,
  Card,
  LoadingButton,
  ReadOnlyField,
  Stack,
  LocalizedString,
} from "design-system";
import { palette } from "design-system/lib/styleConstants";
import { option, taskEither } from "fp-ts";
import { constant, constFalse, constTrue, pipe } from "fp-ts/function";
import { Option } from "fp-ts/Option";
import { useFormatMessage } from "../intl";
import { useParentSharedReducer } from "../BranchExperience/useSharedReducer";
import { reducerConfig, foldState } from "./state";
import * as otpAPI from "../OTP/domain";
import { GenericError } from "../globalDomain";
import { ReaderTaskEither } from "fp-ts/ReaderTaskEither";

import { useSnackBar } from "../useSnackbar";
import { getGenerateOTP } from "./getGenerateOTP";

type Props = {
  phoneNumber: string;
  onGenerateOTP: ReaderTaskEither<
    otpAPI.OtpGenerationInput,
    otpAPI.OtpGenerationError | GenericError,
    otpAPI.OtpGenerationOutput & { transactionId: Option<LocalizedString> }
  >;
  onFailure: () => unknown;
};

type OTPError =
  | otpAPI.OtpGenerationError
  | otpAPI.OtpVerifyError
  | GenericError;

export function OTPAuthorizationParent(props: Props) {
  const formatMessage = useFormatMessage();
  const [state, dispatch] = useParentSharedReducer(reducerConfig, {
    type: "initial",
  });
  const { showSnackbar } = useSnackBar();

  const transactionId = pipe(
    state,
    foldState<Option<LocalizedString>>({
      whenInitial: constant(option.none),
      whenGenerateOtpError: constant(option.none),
      whenRegenerateOtpError: state => state.transactionId,
      whenInsertingOtp: state => state.transactionId,
      whenNoMoreAttempts: state => state.transactionId,
      whenVerifyOtpError: state => state.transactionId,
    })
  );

  function errorToBanner(error: OTPError) {
    const message = ((): LocalizedString => {
      switch (error.id) {
        case "GenericError":
        case "InvalidFlowId":
          return formatMessage("GenericError");
        case "InvalidOTP":
          return formatMessage("OTPAuthorizationParent.otp.invalidCode", {
            remainingAttempts: error.attemptsLeft,
          });
        case "OTPExpired":
          return formatMessage("OTPAuthorizationParent.otp.expiredCode");
        case "MaxOtpRequestsReached":
          return formatMessage("OTPAuthorizationParent.otp.maxOTPRequests");
        case "MaxOtpAttemptsReached":
          return formatMessage("OTPAuthorizationParent.otp.maxOTPAttempts");
      }
    })();
    return (
      <Banner
        type="error"
        content={message}
        title={option.none}
        actions={option.none}
      />
    );
  }

  const generateOTP = getGenerateOTP({
    ...props,
    onStart: () => dispatch({ type: "SetOtpPending", pending: true }),
    onEnd: () => dispatch({ type: "SetOtpPending", pending: false }),
    onError: error => dispatch({ type: "SetGenerateOtpError", error }),
    onSuccess: (transactionId, remainingOtpRequests) =>
      dispatch({
        type: "HandleReceiveOTP",
        transactionId,
        remainingOtpRequests,
      }),
  });

  const resendOtp = {
    action: pipe(
      generateOTP,
      taskEither.chain(() =>
        taskEither.fromIO(() => {
          showSnackbar({
            type: "success",
            action: option.none,
            message: formatMessage("Identification.otp.newCodeSent"),
          });
        })
      )
    ),
    label: formatMessage("Identification.otp.resendCode"),
  };

  const errorBanner = pipe(
    state,
    foldState<Option<JSX.Element>>({
      whenInitial: constant(option.none),
      whenGenerateOtpError: constant(option.none),
      whenRegenerateOtpError: state => option.some(errorToBanner(state.error)),
      whenInsertingOtp: constant(option.none),
      whenNoMoreAttempts: () =>
        option.some(errorToBanner({ id: "MaxOtpAttemptsReached" })),
      whenVerifyOtpError: state => option.some(errorToBanner(state.error)),
    })
  );

  const warningBanner = pipe(
    state,
    foldState<Option<JSX.Element>>({
      whenInitial: constant(option.none),
      whenGenerateOtpError: constant(option.none),
      whenRegenerateOtpError: constant(option.none),
      whenInsertingOtp: s =>
        pipe(
          <Banner
            type="warning"
            title={option.none}
            content={formatMessage("Identification.otp.lastRequestWarning")}
            actions={option.none}
            onDismiss={option.none}
          />,
          option.fromPredicate(constant(s.remainingOtpRequests === 0))
        ),
      whenNoMoreAttempts: constant(option.none),
      whenVerifyOtpError: constant(option.none),
    })
  );

  const displayResendCode = pipe(
    state,
    foldState({
      whenInitial: constFalse,
      whenGenerateOtpError: constTrue,
      whenRegenerateOtpError: constTrue,
      whenInsertingOtp: constTrue,
      whenNoMoreAttempts: constTrue,
      whenVerifyOtpError: constTrue,
    })
  );

  return (
    <Card>
      <Stack column units={8} shrink>
        <ReadOnlyField
          size="medium"
          label={formatMessage("OTPAuthorizationParent.transactionId")}
          value={pipe(transactionId, option.getOrElse(constant("")))}
        />
        {displayResendCode && (
          <Stack units={2} vAlignContent="center">
            <Body size="small" weight="regular" color={palette.neutral700}>
              {formatMessage("OTPAuthorizationParent.otp.notReceivingSMS")}
            </Body>
            <LoadingButton
              variant="text"
              size="small"
              action={resendOtp.action}
              labels={{
                normal: resendOtp.label,
                loading: resendOtp.label,
                error: resendOtp.label,
                success: resendOtp.label,
              }}
            />
          </Stack>
        )}
        {pipe(errorBanner, option.toNullable)}
        {pipe(warningBanner, option.toNullable)}
      </Stack>
    </Card>
  );
}
