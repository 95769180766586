import { taskEither } from "fp-ts";
import { pipe } from "fp-ts/function";
import { IO } from "fp-ts/IO";
import { ReaderTaskEither } from "fp-ts/ReaderTaskEither";
import * as otpAPI from "../OTP/domain";
import { GenericError } from "../globalDomain";
import { Option } from "fp-ts/Option";
import { NonNegativeInteger, LocalizedString } from "design-system";
import { ApplicationLimitError } from "../MortgageDashboard/domain";

export const getGenerateOTP = ({
  onStart,
  onEnd,
  onError,
  onFailure,
  onSuccess,
  onGenerateOTP,
  phoneNumber,
}: {
  onStart: IO<unknown>;
  onEnd: IO<void>;
  onError: (error: otpAPI.OtpGenerationError | GenericError) => unknown;
  onFailure: IO<unknown>;
  onSuccess: (
    transactionId: Option<LocalizedString>,
    remainingOtpRequests: NonNegativeInteger
  ) => unknown;
  phoneNumber: string;
  onGenerateOTP: ReaderTaskEither<
    otpAPI.OtpGenerationInput,
    otpAPI.OtpGenerationError | GenericError | ApplicationLimitError,
    otpAPI.OtpGenerationOutput & { transactionId: Option<LocalizedString> }
  >;
}) =>
  pipe(
    taskEither.bracket(
      taskEither.rightIO(onStart),
      () =>
        pipe(
          onGenerateOTP({
            phoneNumber: phoneNumber,
          }),
          taskEither.orElse(error =>
            taskEither.leftIO(() => {
              if (error.id === "ApplicationLimitPerClientExceeded") {
                return onFailure();
              }
              return onError(error);
            })
          ),
          taskEither.chainW(({ transactionId, remainingRequests }) =>
            taskEither.fromIO(() => onSuccess(transactionId, remainingRequests))
          )
        ),
      () => taskEither.rightIO(onEnd)
    )
  );
