import {
  Body,
  Box,
  Button,
  Card,
  FormSubSection,
  PlusIcon,
  Stack,
  Space,
  Children,
  LocalizedString,
} from "design-system";

import { boolean, option } from "fp-ts";
import { constNull, pipe } from "fp-ts/function";
import { Option } from "fp-ts/Option";
import { useFormatMessage } from "../../../intl";

type Props = {
  title: LocalizedString;
  addNew: Option<{
    label: LocalizedString;
    action: () => unknown;
    disabled?: boolean;
  }>;
  children: Array<Children>;
};

export function LiabilitySection(props: Props) {
  const formatMessage = useFormatMessage();

  return (
    <Box column grow shrink>
      <Stack column units={4}>
        {pipe(
          props.children.length > 0,
          boolean.fold<Children>(
            () => (
              <FormSubSection
                title={props.title}
                children={[
                  <Body
                    size="small"
                    weight="regular"
                    children={[
                      formatMessage("Mortgage.CBResults.Liabilities.noItems", {
                        items: props.title,
                      }),
                    ]}
                  />,
                ]}
              />
            ),
            () => (
              <FormSubSection
                title={props.title}
                children={[...props.children].map((child, index) => (
                  <Card key={index}>{child}</Card>
                ))}
              />
            )
          )
        )}
        {pipe(
          props.addNew,
          option.fold(constNull, addNew => (
            <Box>
              <Button
                variant="text"
                size="default"
                icon={PlusIcon}
                label={addNew.label}
                action={addNew.action}
                disabled={addNew.disabled}
              />
            </Box>
          ))
        )}
      </Stack>
      <Space units={4} />
    </Box>
  );
}
