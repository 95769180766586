import { NonNegativeInteger, LocalizedString } from "design-system";
import { either, eq, nonEmptyArray, option, record, semigroup } from "fp-ts";
import { Eq } from "fp-ts/Eq";
import { pipe } from "fp-ts/function";
import { NonEmptyArray } from "fp-ts/NonEmptyArray";
import * as t from "io-ts";
import { optionFromNullable } from "io-ts-types/optionFromNullable";
import { mapOutput } from "io-ts-types/lib/mapOutput";
import { nonEmptyArray as nonEmptyArrayCodec } from "io-ts-types/lib/nonEmptyArray";
import { NonEmptyString } from "io-ts-types/lib/NonEmptyString";
import { DateFromISOString } from "io-ts-types/lib/DateFromISOString";
import {
  DateFromSQLString,
  MaritalStatus,
  MoneyAmount,
  MonthYear,
  optionFromUndefined,
  optionFromArray,
  PercentageLikeFromNumber,
  GenericError,
  optional,
  Sex,
  CountryCode,
} from "../globalDomain";
import { AddressOCR, PersonalData, PersonalDocument } from "../IdUpload/domain";
import { OtpVerifyError } from "../OTP/domain";
import { DocumentType } from "../UploadDocuments/domain";
import {
  AppraisalType,
  ConstructionType,
  PurposeOfUse,
  SubPropertyType,
} from "./PropertyAndAppraisal/domain";
import { RuntimeLocaleKey } from "../intl";
import { withFallback } from "io-ts-types/withFallback";
import { boolean } from "io-ts";

export const ApplicantIndex = t.type({
  applicantIndex: NonNegativeInteger,
});

export type ApplicantIndex = t.TypeOf<typeof ApplicantIndex>;
export interface withApplicantIndex<C extends t.Mixed>
  extends t.Type<
    ApplicantIndex & t.TypeOf<C>,
    { applicantIndex: string } & t.TypeOf<C>,
    unknown
  > {}
export function withApplicantIndex<C extends t.Mixed>(
  codec: C,
  name: string = `WithApplicantIndex<${codec.name}>`
) {
  return mapOutput(
    t.intersection([ApplicantIndex, codec], name),
    ({ applicantIndex, ...value }) => ({
      applicantIndex: String(applicantIndex),
      ...value,
    })
  );
}

export function eqWithApplicantIndex<O>(
  codecEq: Eq<O>
): Eq<ApplicantIndex & O> {
  return eq.fromEquals(
    (
      { applicantIndex: applicantIndexA, ...a },
      { applicantIndex: applicantIndexB, ...b }
    ) =>
      codecEq.equals(a as any, b as any) &&
      eq.eqNumber.equals(applicantIndexA, applicantIndexB)
  );
}

export type ApplicantRecord<T> = NonEmptyArray<
  ApplicantIndex & {
    data: T;
  }
>;

export interface ApplicantsRecordC<C extends t.Mixed>
  extends t.Type<
    ApplicantRecord<t.TypeOf<C>>,
    Record<string, t.OutputOf<C>>,
    unknown
  > {}
export function ApplicantsRecordC<C extends t.Mixed>(
  codec: C,
  defaultValue?: t.TypeOf<C>,
  name: string = `ApplicantsRecord<${codec.name}>`
): ApplicantsRecordC<C> {
  const applicantsRecord = t.record(t.string, codec);

  return new t.Type(
    name,
    nonEmptyArrayCodec(
      withApplicantIndex(
        t.type({
          data: codec,
        })
      )
    ).is,
    (unknown, context) => {
      return pipe(
        applicantsRecord.validate(unknown, context),
        either.chain(values =>
          pipe(
            defaultValue && record.isEmpty(values)
              ? { 0: defaultValue }
              : values,
            record.toArray,
            nonEmptyArray.fromArray,
            option.map(
              nonEmptyArray.traverse(
                either.getValidation(semigroup.getFirstSemigroup<t.Errors>())
              )(([applicantIndex, value]) =>
                pipe(
                  NonNegativeInteger.decode(Number(applicantIndex)),
                  either.map(applicantIndex => ({
                    applicantIndex,
                    data: value,
                  }))
                )
              )
            ),
            option.getOrElse(() => t.failure(unknown, context))
          )
        )
      );
    },
    nonEmptyArray.reduce({}, (b, { applicantIndex, data }) => ({
      ...b,
      [applicantIndex]: codec.encode(data),
    }))
  );
}

export const ApplicantData = t.type({
  documentType: optionFromUndefined(DocumentType),
  personalData: optionFromUndefined(PersonalData),
  documentDetails: optionFromUndefined(PersonalDocument),
  permanentAddress: optionFromUndefined(AddressOCR),
  currentAddress: optionFromUndefined(AddressOCR),
  contactAddress: optionFromUndefined(AddressOCR),
});
export type ApplicantData = t.TypeOf<typeof ApplicantData>;

export const SoniaStatus = t.type(
  //should be imported from ClientProfile domain
  {
    updated: withFallback(t.boolean, false),
    updateDate: optionFromUndefined(DateFromSQLString),
  },
  "SoniaStatus"
);
export type SoniaStatus = t.TypeOf<typeof SoniaStatus>;

export const ApplicantProfile = t.type({
  name: NonEmptyString,
  surname: NonEmptyString,
  phoneNumber: optionFromUndefined(NonEmptyString),
  phoneChecked: t.boolean,
  email: optionFromUndefined(NonEmptyString),
  emailChecked: t.boolean,
  idChecked: t.boolean,
  customerData: optionFromUndefined(ApplicantData),
  soniaStatus: optionFromUndefined(SoniaStatus),
  existingClient: t.boolean,
});

export type ApplicantProfile = t.TypeOf<typeof ApplicantProfile>;

const ApplicantRegistersDataIncompleteProfile = t.type(
  {
    completedClientProfile: t.literal(false),
    name: NonEmptyString,
    surname: NonEmptyString,
  },
  "ApplicantRegistersDataIncompleteProfile"
);

const ApplicantRegistersDataCompleteProfile = t.type(
  {
    completedClientProfile: t.literal(true),
    name: NonEmptyString,
    surname: NonEmptyString,
    phoneNumber: NonEmptyString,
  },
  "ApplicantRegistersDataCompleteProfile"
);

export const ApplicantRegistersData = t.union(
  [
    ApplicantRegistersDataIncompleteProfile,
    ApplicantRegistersDataCompleteProfile,
  ],
  "ApplicantRegistersData"
);

export type ApplicantRegistersData = t.TypeOf<typeof ApplicantRegistersData>;

export const CreditBureauStatus = t.keyof({
  PENDING: true,
  SUCCESS: true,
  REJECTED: true,
});
export type CreditBureauStatus = t.TypeOf<typeof CreditBureauStatus>;

export const StatusColor = t.keyof({
  White: true,
  Gray: true,
  Red: true,
  Yellow: true,
  Green: true,
});
export type StatusColor = t.TypeOf<typeof StatusColor>;

export const CreditType = t.keyof({
  MORTGAGE_LOAN: true,
  CONSUMER_LOAN: true,
});

export type CreditType = t.TypeOf<typeof CreditType>;

export const LoanPurpose = t.keyof({
  PURPOSE: true,
  NOT_PURPOSE: true,
});

export type LoanPurpose = t.TypeOf<typeof LoanPurpose>;

export const OriginalLoanPurpose = t.keyof({
  PURCHASE: true,
  RECONSTRUCTION: true,
  CONSTRUCTION: true,
  REVERSE_REPAYMENT: true,
  NOT_PURPOSE: true,
  REPAYMENT_OF_PREVIOUSLY_GRANTED_LOAN: true,
  REPAYMENT_OF_PREVIOUSLY_GRANTED_NON_PURPOSE_LOAN: true,
});

export type OriginalLoanPurpose = t.TypeOf<typeof OriginalLoanPurpose>;

export const RefinanceLoansForLivingSelection = t.keyof({
  REFINANCE_WITH_LOANS_FOR_LIVING_TRUE: true,
  REFINANCE_WITH_LOANS_FOR_LIVING_FALSE: true,
  REFINANCE_WITH_ANY_LOANS_FOR_LIVING: true,
});

export type RefinanceLoansForLivingSelection = t.TypeOf<
  typeof RefinanceLoansForLivingSelection
>;

export const ConsumerLoanOrMortgage = t.type(
  {
    creditType: optionFromUndefined(CreditType),
    currentBalance: optionFromUndefined(MoneyAmount),
    finalDate: optionFromUndefined(DateFromSQLString),
    loanPurpose: optionFromUndefined(LoanPurpose),
    monthlyInstallment: optionFromUndefined(MoneyAmount),
    approvedCreditAmount: optionFromUndefined(MoneyAmount),
    canBeRefinanced: optionFromUndefined(t.boolean),
    sharedCreditCoApplicantName: optionFromUndefined(NonEmptyString),
    loansForLiving: optionFromUndefined(t.boolean),
    repaidBeforeDisbursement: optionFromUndefined(t.boolean),
    propertyOwner: optionFromUndefined(t.boolean),
    contractNumber: optionFromUndefined(t.string),
    originalLoanPurpose: optionFromUndefined(OriginalLoanPurpose),
    displayOriginalLoanPurpose: optionFromUndefined(t.boolean),
    displayLoansForLiving: optionFromUndefined(t.boolean),
    loansForLivingDefaultValue: optionFromUndefined(t.boolean),
    refinanceLoansForLivingSelection: optionFromUndefined(
      RefinanceLoansForLivingSelection
    ),
    provider: optionFromUndefined(NonEmptyString),
    usedForRefinance: optionFromUndefined(t.boolean),
    recordId: NonEmptyString,
    manuallyAdded: t.boolean,
    originalLoanAmount: optionFromUndefined(MoneyAmount),
  },
  "ConsumerLoanOrMortgage"
);

export type ConsumerLoanOrMortgage = t.TypeOf<typeof ConsumerLoanOrMortgage>;

export const ConsumerLoanOrMortgageAdd = t.type(
  {
    creditType: optionFromUndefined(CreditType),
    currentBalance: MoneyAmount,
    endDate: MonthYear,
    loanPurpose: optionFromUndefined(LoanPurpose),
    monthlyInstallment: MoneyAmount,
    approvedCreditAmount: MoneyAmount,
    provider: optionFromUndefined(NonEmptyString),
    usedForRefinance: optionFromUndefined(t.boolean),
    manuallyAdded: t.boolean,
    loansForLiving: t.boolean,
    repaidBeforeDisbursement: optionFromUndefined(t.boolean),
    propertyOwner: optionFromUndefined(t.boolean),
    contractNumber: optionFromUndefined(t.string),
    originalLoanPurpose: optionFromUndefined(OriginalLoanPurpose),
  },
  "ConsumerLoanOrMortgageAdd"
);

export type ConsumerLoanOrMortgageAdd = t.TypeOf<
  typeof ConsumerLoanOrMortgageAdd
>;

export const Overdraft = t.type(
  {
    currentBalance: optionFromUndefined(MoneyAmount),
    overdraftLimit: optionFromUndefined(MoneyAmount),
    repaidBeforeDisbursement: optionFromUndefined(t.boolean),
    changeLimit: optionFromUndefined(t.boolean),
    provider: optionFromUndefined(NonEmptyString),
    contractNumber: optionFromUndefined(t.string),
    newOverdraftLimit: optionFromUndefined(MoneyAmount),
    recordId: NonEmptyString,
    manuallyAdded: t.boolean,
    usedForRefinance: optionFromUndefined(t.boolean),
    includedInBonita: optionFromUndefined(t.boolean),
  },
  "Overdraft"
);

export type Overdraft = t.TypeOf<typeof Overdraft>;

export const OverdraftAdd = t.type(
  {
    currentBalance: MoneyAmount,
    overdraftLimit: MoneyAmount,
    repaidBeforeDisbursement: optionFromUndefined(t.boolean),
    changeLimit: optionFromUndefined(t.boolean),
    provider: optionFromUndefined(NonEmptyString),
    contractNumber: optionFromUndefined(t.string),
    newOverdraftLimit: optionFromUndefined(MoneyAmount),
    manuallyAdded: t.boolean,
  },
  "OverdraftAdd"
);

export type OverdraftAdd = t.TypeOf<typeof OverdraftAdd>;

export const CreditCard = t.type(
  {
    currentBalance: optionFromUndefined(MoneyAmount),
    creditCardLimit: optionFromUndefined(MoneyAmount),
    repaidBeforeDisbursement: optionFromUndefined(t.boolean),
    changeLimit: optionFromUndefined(t.boolean),
    provider: optionFromUndefined(NonEmptyString),
    contractNumber: optionFromUndefined(t.string),
    newCreditCardLimit: optionFromUndefined(MoneyAmount),
    recordId: NonEmptyString,
    manuallyAdded: t.boolean,
    usedForRefinance: optionFromUndefined(t.boolean),
    includedInBonita: optionFromUndefined(t.boolean),
  },
  "CreditCard"
);

export type CreditCard = t.TypeOf<typeof CreditCard>;

export const CreditCardAdd = t.type(
  {
    currentBalance: MoneyAmount,
    creditCardLimit: MoneyAmount,
    repaidBeforeDisbursement: optionFromUndefined(t.boolean),
    changeLimit: optionFromUndefined(t.boolean),
    provider: optionFromUndefined(NonEmptyString),
    contractNumber: optionFromUndefined(t.string),
    newCreditCardLimit: optionFromUndefined(MoneyAmount),
    manuallyAdded: t.boolean,
  },
  "CreditCardAdd"
);

export type CreditCardAdd = t.TypeOf<typeof CreditCardAdd>;

export const Provider = t.type({
  code: NonEmptyString,
  name: LocalizedString,
});

export type Provider = t.TypeOf<typeof Provider>;

export const BranchesList = t.record(t.string, t.string);

export type BranchesList = t.TypeOf<typeof BranchesList>;

export const BankersList = t.record(t.string, t.string);

export type BankersList = t.TypeOf<typeof BankersList>;

export const ApplicationAccessMode = t.keyof({
  VIEW_MODE: true,
  EDIT_MODE: true,
  UNAUTHORIZED: true,
});

export type ApplicationAccessMode = t.TypeOf<typeof ApplicationAccessMode>;

export const UserType = t.keyof({
  BANKER: true,
  BROKER: true,
});

export type UserType = t.TypeOf<typeof UserType>;

export const AssignedUser = t.type({
  bankerId: optionFromUndefined(t.string),
  branchCode: optionFromUndefined(t.string),
  firstName: optionFromUndefined(t.string),
  lastName: optionFromUndefined(t.string),
  phoneNumber: optionFromUndefined(t.string),
  branchName: optionFromUndefined(t.string),
  branchAddress: optionFromUndefined(t.string),
  brokerageCompany: optionFromUndefined(t.string),
  editable: t.boolean,
  canBeHandedOverTo: optionFromUndefined(UserType),
  handedOverTo: optionFromUndefined(UserType),
});

export type AssignedUser = t.TypeOf<typeof AssignedUser>;

export const AssignedBrokerForBankerInfo = t.type({
  firstName: optionFromUndefined(t.string),
  lastName: optionFromUndefined(t.string),
  phoneNumber: optionFromUndefined(t.string),
  email: optionFromUndefined(t.string),
  brokerageCompany: optionFromUndefined(t.string),
});

export type AssignedBrokerForBankerInfo = t.TypeOf<
  typeof AssignedBrokerForBankerInfo
>;

export const AssignedBrokerForBanker = t.type({
  brokerInfo: optionFromUndefined(AssignedBrokerForBankerInfo),
});

export type AssignedBrokerForBanker = t.TypeOf<typeof AssignedBrokerForBanker>;

export const LoanList = t.type(
  {
    consumerLoansOrMortgagesList: t.array(ConsumerLoanOrMortgage),
    overdraftsList: t.array(Overdraft),
    creditCardsList: t.array(CreditCard),
  },
  "LoanList"
);

export type LoanList = t.TypeOf<typeof LoanList>;

export const ExistingApplication = t.type(
  {
    recordId: NonEmptyString,
    status: optionFromUndefined(t.boolean),
    loanAmount: optionFromUndefined(MoneyAmount),
    dateOfApplication: optionFromUndefined(MonthYear),
    provider: optionFromUndefined(NonEmptyString),
    monthlyInstallment: optionFromUndefined(MoneyAmount),
    overdraftLimit: optionFromUndefined(MoneyAmount),
    creditCardLimit: optionFromUndefined(MoneyAmount),
    sharedCredit: optionFromUndefined(t.boolean),
    sharedCreditCoApplicantName: optionFromUndefined(NonEmptyString),
  },
  "ExistingApplication"
);

export const ApplicationType = t.keyof(
  {
    Mortgage: true,
    ConsumerLoan: true,
    Overdraft: true,
    CreditCard: true,
  },
  "ApplicationType"
);

export type ApplicationType = t.TypeOf<typeof ApplicationType>;

export type ExistingApplication = t.TypeOf<typeof ExistingApplication>;

export const ExistingApplicationStatus = t.keyof(
  {
    Terminated: true,
    WillBeSigned: true,
  },
  "ExistingApplicationStatus"
);
export type ExistingApplicationStatus = t.TypeOf<
  typeof ExistingApplicationStatus
>;

export const ApplicationList = t.type(
  {
    creditCardsList: t.array(ExistingApplication),
    overdraftsList: t.array(ExistingApplication),
    consumerLoansList: t.array(ExistingApplication),
    mortgagesList: t.array(ExistingApplication),
  },
  "ApplicationList"
);

export type ApplicationList = t.TypeOf<typeof ApplicationList>;

export const Residence = t.keyof(
  {
    Prague: true,
    OutsideOfPrague: true,
    OutsideOfCzechRepublic: true,
  },
  "Residence"
);

export type Residence = t.TypeOf<typeof Residence>;

export const HouseholdInfo = t.type(
  {
    currentResidence: optionFromUndefined(Residence),
    currentResidenceLabel: optionFromUndefined(LocalizedString),
    numberOfAdults: t.number,
    numberOfChildren: t.number,
  },
  "HouseholdInfo"
);

export type HouseholdInfo = t.TypeOf<typeof HouseholdInfo>;

const LivingExpenses = t.type(
  {
    minimumMonthlyExpenses: optionFromUndefined(MoneyAmount),
    otherRegularExpenses: MoneyAmount,
    alimony: optionFromUndefined(t.boolean),
    validByCourt: optionFromUndefined(t.boolean),
    alimonyFromCourt: optionFromUndefined(MoneyAmount),
    numberOfChildrenForAlimony: t.number,
    numberOfAdultsForAlimony: t.number,
  },
  "LivingExpenses"
);

const TotalLiabilities = t.type(
  {
    creditCardsLimits: MoneyAmount,
    monthlyInstallments: MoneyAmount,
    overdraftsLimit: MoneyAmount,
    totalLiabilities: MoneyAmount,
  },
  "TotalLiabilities"
);

export type TotalLiabilities = t.TypeOf<typeof TotalLiabilities>;

export const TotalLiabilitiesInput = t.type(
  {
    creditCardsLimits: MoneyAmount,
    monthlyInstallments: MoneyAmount,
    overdraftsLimit: MoneyAmount,
  },
  "TotalLiabilitiesInput"
);

export type TotalLiabilitiesInput = t.TypeOf<typeof TotalLiabilitiesInput>;

export const Expenses = t.type(
  {
    householdInfo: optionFromUndefined(HouseholdInfo, "HouseholdInfo"),
    livingExpenses: optionFromUndefined(LivingExpenses, "LivingExpenses"),
    totalLiabilities: TotalLiabilities,
    sameHouseholdAsApplicant: optionFromUndefined(
      t.boolean,
      "SameHouseholdAsApplicant"
    ),
  },
  "Expenses"
);

export type Expenses = t.TypeOf<typeof Expenses>;

export const EducationCZ = t.keyof({
  Elementary: true,
  Apprenticed: true,
  ApprenticedWithLeavingExam: true,
  SecondaryWithLeavingExam: true,
  College: true,
  University: true,
});

export type EducationCZ = t.TypeOf<typeof EducationCZ>;

export const EducationSK = t.keyof({
  ElementarySchool: true,
  SecondaryWithLeavingExam: true,
  SecondaryWithoutLeavingExam: true,
  UniversityAbroad: true,
  UniversityInSlovakRepublik: true,
});

export type EducationSK = t.TypeOf<typeof EducationSK>;

export const Education = t.union([EducationCZ, EducationSK]);
export type Education = t.TypeOf<typeof Education>;

export const HousingTypeCZ = t.keyof({
  Own: true,
  Rental: true,
  Parents: true,
  Cooperative: true,
  Other: true,
});
export type HousingTypeCZ = t.TypeOf<typeof HousingTypeCZ>;

export const HousingTypeSK = t.keyof({
  Other: true,
  OwnFlat: true,
  OwnHouse: true,
  ProvidedByEmployer: true,
  RentFromHousingAssociation: true,
  RentFromIndividual: true,
  RentFromState: true,
  Parents: true,
});
export type HousingTypeSK = t.TypeOf<typeof HousingTypeSK>;

export const HousingType = t.union([HousingTypeCZ, HousingTypeSK]);
export type HousingType = t.TypeOf<typeof HousingType>;

export const PersonalInformation = t.type({
  maritalStatus: optionFromUndefined(MaritalStatus),
  maritalStatusLabel: optionFromUndefined(LocalizedString),
  commonProperty: optionFromUndefined(t.boolean),
  commonPropertyLessThan6M: optionFromUndefined(t.boolean),
  education: optionFromUndefined(Education),
  educationLabel: optionFromUndefined(LocalizedString),
  divorcedMoreThan3Y: optionFromUndefined(t.boolean),
});
export type PersonalInformation = t.TypeOf<typeof PersonalInformation>;

export const CurrentAddress = t.type({
  housingType: optionFromUndefined(HousingType),
  housingTypeLabel: optionFromUndefined(LocalizedString),
  currentAddressSince: optionFromUndefined(MonthYear),
});
export type CurrentAddress = t.TypeOf<typeof CurrentAddress>;

export const AdditionalDetails = t.type({
  personalInformation: PersonalInformation,
  currentAddress: CurrentAddress,
});
export type AdditionalDetails = t.TypeOf<typeof AdditionalDetails>;

export const CPIAnswers = t.type(
  {
    ageBetweenLimits: optionFromUndefined(t.boolean),
    incapacityToWork: t.boolean,
    cancer: t.boolean,
    invalidity: t.boolean,
    selfEmployed: optionFromUndefined(t.boolean),
    // CZ answers
    disability: optionFromUndefined(t.boolean),
    medicalSupervision: optionFromUndefined(t.boolean),
    noticePeriod: optionFromUndefined(t.boolean),
    probation: optionFromUndefined(t.boolean),
    stateEmployment: optionFromUndefined(t.boolean),
    // SK answers
    situationListedAbove: optionFromUndefined(t.boolean),
    ztpCard: optionFromUndefined(t.boolean),
    employed: optionFromUndefined(t.boolean),
  },
  "CPIAnswers"
);
export type CPIAnswers = t.TypeOf<typeof CPIAnswers>;

export const CPIEligibilityStatus = t.keyof({
  ELIGIBLE: true,
  PARTIAL_ELIGIBLE: true,
  NOT_ELIGIBLE: true,
});

export type CPIEligibilityStatus = t.TypeOf<typeof CPIEligibilityStatus>;

export const CPIData = t.type({
  cpiAnswers: ApplicantsRecordC(optionFromUndefined(CPIAnswers), option.none),
  cpiEligibility: ApplicantsRecordC(
    optionFromUndefined(CPIEligibilityStatus),
    option.none
  ),
});
export type CPIData = t.TypeOf<typeof CPIData>;

export const ApplicantDetailsConfig = t.type({
  hasMaritalStatus: t.boolean,
  maritalStatus: optionFromUndefined(MaritalStatus),
  hasBirthDate: t.boolean,
  hasEmploymentInfo: t.boolean,
  hasProbationInfo: t.boolean,
  hasCompletedCPI: t.boolean,
  hasCompletedPreScoring: t.boolean,
  hasMainIncomeTypeEmployed: t.boolean,
  sameHousehold: t.boolean,
});
export type ApplicantDetailsConfig = t.TypeOf<typeof ApplicantDetailsConfig>;

export const BonitaResults = t.intersection(
  [
    t.type(
      {
        debtRatio: PercentageLikeFromNumber,
        maxLoanAmount: t.number,
        maxMonthlyInstallment: t.number,
        stressDebtRatio: PercentageLikeFromNumber,
        totalActualExpenses: t.number,
        debtServiceToIncomeRatio: optionFromUndefined(PercentageLikeFromNumber),
        debtToIncomeRatio: optionFromUndefined(PercentageLikeFromNumber),
        dtiOverLimit: t.boolean,
        dstiOverLimit: t.boolean,
      },
      "Results"
    ),
    t.union(
      [
        t.type(
          {
            simplifiedVersion: t.literal(false),
            eligibleIncome: t.number,
          },
          "Full"
        ),
        t.type(
          {
            simplifiedVersion: t.literal(true),
          },
          "Simplified"
        ),
      ],
      "Type"
    ),
  ],
  "BonitaResults"
);

export type BonitaResults = t.TypeOf<typeof BonitaResults>;

export const BonitaError = t.keyof({
  NOT_UPDATED: true,
  TECHNICAL_ERROR: true,
});
export type BonitaError = t.TypeOf<typeof BonitaError>;

export const PropertyAndAppraisalDetails = t.type({
  propertyType: optionFromUndefined(SubPropertyType),
  address: optionFromUndefined(NonEmptyString),
  actualValue: optionFromUndefined(MoneyAmount),
  futureValue: optionFromUndefined(MoneyAmount),
});

export type PropertyAndAppraisalDetails = t.TypeOf<
  typeof PropertyAndAppraisalDetails
>;

export const PropertyAndAppraisalDetailsResponse = t.type({
  listOfPropertyAndAppraisalDetails: optionFromArray(
    PropertyAndAppraisalDetails
  ),
  sectionAutomaticallyValidated: t.boolean,
});

export type PropertyAndAppraisalDetailsResponse = t.TypeOf<
  typeof PropertyAndAppraisalDetailsResponse
>;

export const OfferError = t.keyof(
  {
    EXPIRED: true,
    TECHNICAL_ERROR: true,
    DATE_OF_BIRTH_UPDATED: true,
    INELIGIBLE_CPI_PACKAGE: true,
    CPI_RESULT_ERROR: true,
    NO_CPI_PACKAGE_AVAILABLE: true,
    HQ_ONLY_APPLICANT_INELIGIBLE: true,
    HQ_BOTH_APPLICANTS_INELIGIBLE: true,
    HQ_CHANGE_INSURED_CLIENT: true,
    INTEREST_RATE_CHANGED: true,
  },
  "OfferError"
);
export type OfferError = t.TypeOf<typeof OfferError>;

export const OfferWarning = t.keyof({
  OFFER_TOO_HIGH: true,
  MAIN_APPLICANT_INVALID_MAX_TENOR: true,
  CO_APPLICANT_INVALID_MAX_TENOR: true,
  LTV_OVER_LIMIT: true,
});
export type OfferWarning = t.TypeOf<typeof OfferWarning>;

export const BonitaApplicantState = t.type(
  {
    hasCBLiabilities: t.boolean,
    hasIncomeDetails: t.boolean,
    hasExpensesDetails: t.boolean,
    hasPreApprovedIncome: optionFromNullable(t.boolean),
    isMarried: t.boolean,
  },
  "BonitaApplicantState"
);
export type BonitaApplicantState = t.TypeOf<typeof BonitaApplicantState>;

export const ApplicationStatus = t.keyof({
  APPROVED: true,
  HARD_KO: true,
  MANUAL: true,
  REJECTED: true,
  TIMEOUT: true,
});
export type ApplicationStatus = t.TypeOf<typeof ApplicationStatus>;

export const ErrorProfileType = t.keyof({
  CLIENT_UNDERAGE: true,
  CLIENT_OVERAGE: true,
});

export const ClientProfileError = ApplicantsRecordC(
  t.type({
    applicantIndex: t.string,
    error: ErrorProfileType,
  })
);

export type ClientProfileError = t.TypeOf<typeof ClientProfileError>;

export type ErrorProfileType = t.TypeOf<typeof ErrorProfileType>;

const ApplicationIsLockedError = t.type({
  id: t.literal("ApplicationIsLocked"),
});

const InvalidClientIDError = t.type({
  id: t.literal("InvalidClientID"),
});

const CardRecalledError = t.type({
  id: t.literal("CardRecalled"),
});

const BonitaStatusIncompleteError = t.type({
  id: t.literal("BonitaStatusIncomplete"),
});

export const MTGSubmitApplicationError = t.union([
  ApplicationIsLockedError,
  BonitaStatusIncompleteError,
  GenericError,
]);

export type MTGSubmitApplicationError = t.TypeOf<
  typeof MTGSubmitApplicationError
>;

export const MTG3POtpVerifyError = t.union([
  OtpVerifyError,
  ApplicationIsLockedError,
  InvalidClientIDError,
  CardRecalledError,
]);

export type MTG3POtpVerifyError = t.TypeOf<typeof MTG3POtpVerifyError>;

export const ApplicationLimitError = t.type({
  id: t.literal("ApplicationLimitPerClientExceeded"),
  name: t.string,
});

export type ApplicationLimitError = t.TypeOf<typeof ApplicationLimitError>;

export const ApplicationInvalidPhaseError = t.type({
  id: t.literal("ApplicationInvalidPhase"),
});
export type ApplicationInvalidPhaseError = t.TypeOf<
  typeof ApplicationInvalidPhaseError
>;

export const PreliminaryDecisionStatus = t.keyof({
  OK: true,
  INDIVIDUAL_ASSESSMENT: true,
  NOT_OK_CAN_ESCALATE: true,
  NOT_OK_CANNOT_ESCALATE: true,
  AUTOMATIC_DECISION_NOT_AVAILABLE: true,
  ERROR: true,
  TIMEOUT: true,
});
export type PreliminaryDecisionStatus = t.TypeOf<
  typeof PreliminaryDecisionStatus
>;

export const FinalDecisionStatus = t.keyof({
  OK: true,
  DECLINE_CAN_ESCALATE: true,
  DECLINE_CANNOT_ESCALATE: true,
  REWORK: true,
  ERROR: true,
  TIMEOUT: true,
  CONTRACT_INITIATED: true,
});
export type FinalDecisionStatus = t.TypeOf<typeof FinalDecisionStatus>;

export const SectionName = t.keyof({
  Offer: true,
  Registers: true,
  Overview: true,
});
export type SectionName = t.TypeOf<typeof SectionName>;

export const ReworkAction = t.type(
  {
    comment: t.string,
    reworkCategory: t.string,
  },
  "ReworkAction"
);

export type ReworkAction = t.TypeOf<typeof ReworkAction>;

export const ReworkSection = t.keyof(
  {
    AdditionalDetails: true,
    Bonita: true,
    ClientProfile: true,
    Documents: true,
    Offer: true,
    PropertyAndAppraisal: true,
    PurposeAndFinancing: true,
    Registers: true,
  },
  "ReworkSection"
);

export type ReworkSection = t.TypeOf<typeof ReworkSection>;

export const ReworkActions = t.type(
  {
    reworkActions: t.array(ReworkAction),
    reworkSections: t.array(ReworkSection),
  },
  "ReworkActions"
);

export type ReworkActions = t.TypeOf<typeof ReworkActions>;

/**
 * REWORK DATA
 */

/**
 * ReworkAdditionalDetails
 */
const ReworkCurrentAddress = optional(
  {
    currentAddressSince: MonthYear,
    housingType: HousingType,
  },
  "ReworkCurrentAddress"
);

export type ReworkCurrentAddress = t.TypeOf<typeof ReworkCurrentAddress>;

const ReworkPersonalInformation = optional(
  {
    commonProperty: t.boolean,
    commonPropertyLessThan6M: t.boolean,
    divorcedMoreThan3Y: t.boolean,
    education: Education,
    martialStatus: MaritalStatus,
  },
  "ReworkPersonalInformation"
);

export type ReworkPersonalInformation = t.TypeOf<
  typeof ReworkPersonalInformation
>;

const ReworkAdditionalDetailsData = optional(
  {
    currentAddress: ReworkCurrentAddress,
    personalInformation: ReworkPersonalInformation,
  },
  "ReworkAdditionalDetailsData"
);

export type ReworkAdditionalDetailsData = t.TypeOf<
  typeof ReworkAdditionalDetailsData
>;

const ReworkAdditionalDetails = t.type(
  {
    additionalDetails: ApplicantsRecordC(
      optionFromUndefined(ReworkAdditionalDetailsData),
      option.none
    ),
  },
  "ReworkAdditionalDetails"
);

export type ReworkAdditionalDetails = t.TypeOf<typeof ReworkAdditionalDetails>;

/**
 * ReworkBonita
 */
const ReworkHouseholdInfo = optional(
  {
    applicantIndex: t.string,
    currentResidence: t.string,
    currentResidenceLabel: t.string,
    numberOfAdults: t.number,
    numberOfChildren: t.number,
  },
  "ReworkHouseholdInfo"
);

export type ReworkHouseholdInfo = t.TypeOf<typeof ReworkHouseholdInfo>;

const ReworkLivingExpenses = optional(
  {
    alimonyExpenses: MoneyAmount,
    minimumMonthlyExpenses: MoneyAmount,
    otherRegularExpenses: MoneyAmount,
    alimony: boolean,
    validByCourt: boolean,
    alimonyFromCourt: MoneyAmount,
    numberOfChildrenForAlimony: t.number,
    numberOfAdultsForAlimony: t.number,
  },
  "ReworkLivingExpenses"
);

export type ReworkLivingExpenses = t.TypeOf<typeof ReworkLivingExpenses>;

const ReworkTotalLiabilities = optional(
  {
    creditCardsLimits: MoneyAmount,
    monthlyInstallments: MoneyAmount,
    overdraftsLimit: MoneyAmount,
    totalLiabilities: MoneyAmount,
  },
  "ReworkTotalLiabilities"
);

export type ReworkTotalLiabilities = t.TypeOf<typeof ReworkTotalLiabilities>;

const ReworkExpenses = optional(
  {
    applicantIndex: t.string,
    householdInfo: ReworkHouseholdInfo,
    livingExpenses: ReworkLivingExpenses,
    sameHouseholdAsApplicant: t.boolean,
    totalLiabilities: ReworkTotalLiabilities,
  },
  "ReworkExpenses"
);

export type ReworkExpenses = t.TypeOf<typeof ReworkExpenses>;

const ReworkCompanyInfo = optional(
  {
    applicantsShareHigherThan33: t.boolean,
    businessSector: t.string,
    businessStartingDate: MonthYear,
    companyIco: t.string,
    companyName: t.string,
    addIco: t.boolean,
    nameOfEntrepreneur: t.string,
    entrepreneurIco: t.string,
    country: t.string,
    street: t.string,
    phone: t.string,
    equityPositive: t.boolean,
    freelancerType: t.string,
    nameOf3P: t.string,
    stakeInCompany: t.number,
  },
  "ReworkCompanyInfo"
);

export type ReworkCompanyInfo = t.TypeOf<typeof ReworkCompanyInfo>;

const ReworkContractInfo = optional(
  {
    contractIndefinitePeriod: t.boolean,
    contractPeriodExpiration: MonthYear,
    employedInSpecificProfessions: t.boolean,
    employmentType: t.string,
    inProbation: t.boolean,
    jobPosition: t.string,
    lengthOfUninterruptedCurrentEmployment: t.number,
    lengthOfUninterruptedOverallEmployment: t.number,
    paymentMethod: t.string,
    paymentMethodLabel: t.string,
    startingDate: MonthYear,
    workBasedOnAgreementOnWorkingActivity: t.boolean,
  },
  "ReworkContractInfo"
);

export type ReworkContractInfo = t.TypeOf<typeof ReworkContractInfo>;

const ReworkIncomeInfo = optional(
  {
    alimonyChildrenInvolved: t.number,
    allowanceType: t.string,
    amortizationOfRentedRe: t.number,
    annualGrossIncome: t.number,
    bruttoComissions: t.number,
    businessGrossIncome: t.number,
    employedInCompany: t.boolean,
    equityFromLastYear: t.number,
    grossIncomes: t.number,
    incomeAsDeductibleExpenses: t.boolean,
    incomeDescription: t.string,
    incomeFromRentContract: t.number,
    incomeSource: t.string,
    incomeSourceLabel: t.string,
    isAlimonyIdentifiable: t.boolean,
    isAlimonyOutsideCountry: t.boolean,
    isApplicantDeclareAsUserInDeclaration: t.boolean,
    isCooperativeOwnership: t.boolean,
    keepsAccountancy: t.boolean,
    last3YearsPaidSharedProfit: t.number,
    lastPeriodProfit: t.number,
    monthlyAlimonyIncome: t.number,
    monthlyIncome: t.number,
    monthlyPension: t.number,
    monthlyRent: t.number,
    monthlySocialBenefit: t.number,
    negativeBusinessProfit: t.boolean,
    pensionType: t.string,
    profitSharingIncome: t.boolean,
    r101BruttoIncomes: t.number,
    r201IncomeFromLease: t.number,
    r37PartialTaxBase: t.number,
    r38PartialTaxBase: t.number,
    r39PartialTaxBase: t.number,
    r74TaxAfterClaimedRelief: t.number,
    reCoOwned: t.boolean,
    reInPersonalOwnership: t.boolean,
    reUsedAsCollateral: t.boolean,
    retirementRentType: t.string,
    salaryCurrency: t.string,
    specialTypeOfIncome: t.string,
    tax: t.number,
    taxBase: t.number,
    typeOfRent: t.string,
    youngPeopleConditions: t.boolean,
    taxAdvisor: t.boolean,
    calculationBasedOnTurnover: t.boolean,
    profitLastPeriodPositive: t.boolean,
  },
  "ReworkIncomeInfo"
);

export type ReworkIncomeInfo = t.TypeOf<typeof ReworkIncomeInfo>;

const ReworkAdditionalIncomes = optional(
  {
    companyInfo: ReworkCompanyInfo,
    contractInfo: ReworkContractInfo,
    incomeInfo: ReworkIncomeInfo,
    recordId: t.string,
  },
  "ReworkAdditionalIncomes"
);

export type ReworkAdditionalIncomes = t.TypeOf<typeof ReworkAdditionalIncomes>;

const ReworkIncomes = optional(
  {
    additionalIncomes: t.array(ReworkAdditionalIncomes),
    applicantIndex: t.string,
    companyInfo: ReworkCompanyInfo,
    contractInfo: ReworkContractInfo,
    incomeInfo: ReworkIncomeInfo,
    simplifiedVersion: t.boolean,
    totalAvgMonthlyIncome: t.number,
  },
  "ReworkIncomes"
);

export type ReworkIncomes = t.TypeOf<typeof ReworkIncomes>;

export type CommonReworkIncomes = Omit<
  ReworkIncomes,
  | "additionalIncomes"
  | "applicantIndex"
  | "simplifiedVersion"
  | "totalAvgMonthlyIncome"
>;

const ReworkBonita = optional(
  {
    expenses: ApplicantsRecordC(
      optionFromUndefined(ReworkExpenses),
      option.none
    ),
    incomes: ApplicantsRecordC(optionFromUndefined(ReworkIncomes), option.none),
  },
  "ReworkBonita"
);

export type ReworkBonita = t.TypeOf<typeof ReworkBonita>;

/**
 * ReworkClientProfile
 */
const ReworkDocumentDetails = optional(
  {
    authority: t.string,
    authorityCountry: t.string,
    dateOfIssuing: DateFromSQLString,
    idNumber: t.string,
    issuer: t.string,
    validUntil: DateFromSQLString,
  },
  "ReworkDocumentDetails"
);

export type ReworkDocumentDetails = t.TypeOf<typeof ReworkDocumentDetails>;

const ReworkAddress = optional(
  {
    city: t.string,
    country: CountryCode,
    streetName: t.string,
    streetNumber: t.string,
    zipCode: t.string,
    provinceName: t.string,
  },
  "ReworkAddress"
);

export type ReworkAddress = t.TypeOf<typeof ReworkAddress>;

const ReworkPersonalData = optional(
  {
    birthNumber: t.string,
    citizenship: t.string,
    countryOfBirth: t.string,
    dateOfBirth: DateFromSQLString,
    maritalStatus: MaritalStatus,
    name: t.string,
    nationality: t.string,
    placeOfBirth: t.string,
    sex: Sex,
    surname: t.string,
    title: t.string,
    titleAfter: t.string,
    titleBefore: t.string,
  },
  "ReworkPersonalData"
);

export type ReworkPersonalData = t.TypeOf<typeof ReworkPersonalData>;

const ReworkCustomer = optional(
  {
    documentDetails: ReworkDocumentDetails,
    documentType: DocumentType,
    email: t.string,
    permanentAddress: ReworkAddress,
    personalData: ReworkPersonalData,
    phoneNumber: t.string,
  },
  "ReworkCustomer"
);

export type ReworkCustomer = t.TypeOf<typeof ReworkCustomer>;

const ReworkClientProfileData = optional(
  {
    customer: ReworkCustomer,
  },
  "ReworkClientProfileData"
);

export type ReworkClientProfileData = t.TypeOf<typeof ReworkClientProfileData>;

const ReworkClientProfile = t.type(
  {
    clientProfile: ApplicantsRecordC(
      optionFromUndefined(ReworkClientProfileData),
      option.none
    ),
  },
  "ReworkClientProfile"
);

export type ReworkClientProfile = t.TypeOf<typeof ReworkClientProfile>;

/**
 * ReworkOffer
 */
const ReworkLoanType = t.keyof(
  {
    Combined: true,
    NonPurpose: true,
    Purpose: true,
  },
  "ReworkLoanType"
);

const ReworkOfferResult = optional(
  {
    apr: t.number,
    fixationPeriod: t.number,
    insuranceFee: MoneyAmount,
    interestRate: t.number,
    monthlyInstallment: MoneyAmount,
    selectedOffer: t.string,
  },
  "ReworkOfferResult"
);

const ReworkOfferSummary = optional(
  {
    duration: t.number,
    loanAmount: MoneyAmount,
    loanToValue: t.number,
  },
  "ReworkOfferSummary"
);

const TenorType = t.keyof(
  {
    Years: true,
    Months: true,
  },
  "TenorType"
);

const Tenor = t.type(
  {
    tenorValue: NonNegativeInteger,
    tenorType: TenorType,
  },
  "Tenor"
);

const ReworkOffer = optional(
  {
    appraisalFee: MoneyAmount,
    collateralType: t.string,
    dateOfBirth: t.string,
    dateOfBirthCoApplicant: t.string,
    dayOfInstallment: t.number,
    estimatedPropertyValue: MoneyAmount,
    loanAmount: MoneyAmount,
    loanPurpose: t.string,
    loanType: ReworkLoanType,
    nonPurposeAmount: MoneyAmount,
    nonSpecifiedProperty: t.boolean,
    totalFeesDiscount: MoneyAmount,
    greenMortgage: t.boolean,
    offerResult: ReworkOfferResult,
    offerSummary: ReworkOfferSummary,
    processingFee: MoneyAmount,
    purposeAmount: MoneyAmount,
    selectedInsurance: t.string,
    selectedInsuredClient: t.string,
    tenor: Tenor,
  },
  "ReworkOffer"
);

export type ReworkOffer = t.TypeOf<typeof ReworkOffer>;

/**
 * ReworkPropertyAndAppraisal
 */
const ReworkApartmentInformation = optional(
  {
    isOutOfRange: t.boolean,
    isRecreationOrHighRisk: t.boolean,
    isTechnicalConditionUnmaintained: t.boolean,
    moreThanFiveRooms: t.boolean,
  },
  "ReworkApartmentInformation"
);

export type ReworkApartmentInformation = t.TypeOf<
  typeof ReworkApartmentInformation
>;

const ReworkAppraisalDetails = optional(
  {
    addressOfProperty: t.string,
    dateOfEvaluation: DateFromISOString,
    firstName: t.string,
    lastName: t.string,
    valueOfProperty: MoneyAmount,
  },
  "ReworkAppraisalDetails"
);

export type ReworkAppraisalDetails = t.TypeOf<typeof ReworkAppraisalDetails>;

const ReworkOtherInformation = optional(
  {
    commentsForAppraiser: t.string,
    districtOfProperty: t.string,
    isDifferentContact: t.boolean,
    nameOfContact: t.string,
    phoneNumber: t.string,
  },
  "ReworkOtherInformation"
);

export type ReworkOtherInformation = t.TypeOf<typeof ReworkOtherInformation>;

const ReworkAppraisalQuestions = optional(
  {
    apartmentInformation: ReworkApartmentInformation,
    appraisalDetails: ReworkAppraisalDetails,
    constructionType: ConstructionType,
    developerProject: t.string,
    externalAppraisal: t.boolean,
    floodZoneIV: t.boolean,
    legalConstraint: t.boolean,
    moreThan10Apartments: t.boolean,
    noLegalAccessToTheProperty: t.boolean,
    originalAppraisal: t.boolean,
    otherInformation: ReworkOtherInformation,
    otherRestrictions: t.boolean,
    pledgeForAnotherInstitution: t.boolean,
  },
  "ReworkAppraisalQuestions"
);

export type ReworkAppraisalQuestions = t.TypeOf<
  typeof ReworkAppraisalQuestions
>;

const ReworkAppraisal = optional(
  {
    appraisalQuestions: ReworkAppraisalQuestions,
    appraisalType: AppraisalType,
  },
  "ReworkAppraisal"
);

export type ReworkAppraisal = t.TypeOf<typeof ReworkAppraisal>;

const ReworkRisk = optional(
  {
    additionalNote: t.string,
    checked: t.boolean,
    riskId: t.string,
    riskText: RuntimeLocaleKey,
  },
  "ReworkRisk"
);

export type ReworkRisk = t.TypeOf<typeof ReworkRisk>;

const ReworkPropertyRisk = optional(
  {
    comment: t.string,
    hasRisks: t.boolean,
    riskList: t.array(ReworkRisk),
  },
  "ReworkPropertyRisk"
);

export type ReworkPropertyRisk = t.TypeOf<typeof ReworkPropertyRisk>;

const ReworkPropertyAddress = optional(
  {
    city: t.string,
    numberOfApartment: t.string,
    numberOfDescriptiveOrParcel: t.string,
    postCode: t.string,
    street: t.string,
  },
  "ReworkPropertyAddress"
);

export type ReworkPropertyAddress = t.TypeOf<typeof ReworkPropertyAddress>;

const ReworkSubProperty = optional(
  {
    address: ReworkPropertyAddress,
    purpose: PurposeOfUse,
    subPropertyId: t.string,
    type: SubPropertyType,
  },
  "ReworkSubProperty"
);

export type ReworkSubProperty = t.TypeOf<typeof ReworkSubProperty>;

const ReworkPropertyAndAppraisalItem = optional(
  {
    actualValue: MoneyAmount,
    appraisalFee: MoneyAmount,
    propertyInsuranceFee: MoneyAmount,
    appraisal: ReworkAppraisal,
    futureValue: MoneyAmount,
    isPledged: t.boolean,
    propertyId: t.string,
    propertyRisk: ReworkPropertyRisk,
    subProperties: t.array(ReworkSubProperty),
  },
  "ReworkPropertyAndAppraisalItem"
);

export type ReworkPropertyAndAppraisalItem = t.TypeOf<
  typeof ReworkPropertyAndAppraisalItem
>;

const ReworkPropertyAndAppraisal = optional(
  {
    propertyAndAppraisalList: t.array(ReworkPropertyAndAppraisalItem),
  },
  "ReworkPropertyAndAppraisal"
);

export type ReworkPropertyAndAppraisal = t.TypeOf<
  typeof ReworkPropertyAndAppraisal
>;

/**
 * ReworkPurposeAndFinancing
 */
const Disbursement = t.keyof(
  {
    DepositAccountWithARealEstateAgency: true,
    DirectlyAccordingToThePurpose: true,
    EscrowAccount: true,
    LawyerCustody: true,
    None: true,
    NotApplicable: true,
    NotaryCustody: true,
    WithoutCustody: true,
  },
  "Disbursement"
);

const ReworkRegisteredPledge = t.keyof(
  {
    AlreadyRegisteredPledge: true,
    None: true,
    NotApplicable: true,
    ProposalForRegistrationOfPledge: true,
  },
  "ReworkRegisteredPledge"
);

const IsOwnershipFromHousingType = t.keyof(
  {
    No: true,
    NotApplicable: true,
    Yes: true,
  },
  "IsOwnershipFromHousingType"
);

const ReworkOwnerClientEnum = t.keyof(
  {
    No: true,
    None: true,
    NotApplicable: true,
    Yes: true,
  },
  "PreviousClientsAreCurrentBorrowers"
);

const PurposeOfOriginalInvestment = t.keyof(
  {
    ConstructionOrReconstruction: true,
    None: true,
    NotApplicable: true,
    Purchase: true,
  },
  "PurposeOfOriginalInvestment"
);

const TypeOfAcquiringOfRentalRights = t.keyof(
  {
    AnotherCooperativeShareBuy: true,
    None: true,
    NotApplicable: true,
    ObtainingTheRightToLease: true,
  },
  "TypeOfAcquiringOfRentalRights"
);

const TypeOfSettlement = t.keyof(
  {
    None: true,
    NotApplicable: true,
    SettlementOfCoOwnershipClaims: true,
    SettlementOfInheritanceClaimsOnTheProperty: true,
    SettlementOfPropertyClaims: true,
  },
  "TypeOfSettlement"
);

const ReworkAdditionalInfo = optional(
  {
    disbursement: Disbursement,
    disbursementBasedOnRegisteredPledge: ReworkRegisteredPledge,
    isOwnershipFromHousingType: IsOwnershipFromHousingType,
    previousClientsAreCurrentBorrowers: ReworkOwnerClientEnum,
    propertyOwner: ReworkOwnerClientEnum,
    purposeOfOriginalInvestment: PurposeOfOriginalInvestment,
    stateOfRegisteredPledge: ReworkRegisteredPledge,
    typeOfAcquiringOfRentalRights: TypeOfAcquiringOfRentalRights,
    typeOfSettlement: TypeOfSettlement,
  },
  "ReworkAdditionalInfo"
);

export type ReworkAdditionalInfo = t.TypeOf<typeof ReworkAdditionalInfo>;

const ReworkFinancing = optional(
  {
    amountOfLoan: MoneyAmount,
    amountOfNonOwnFunds: MoneyAmount,
    amountOfOwnFunds: MoneyAmount,
  },
  "ReworkFinancing"
);

export type ReworkFinancing = t.TypeOf<typeof ReworkFinancing>;

const ReworkLoanPurpose = t.keyof(
  {
    CONSTRUCTION: true,
    NON_PURPOSE: true,
    PAYMENT_OF_COOPERATIVE_SHARE: true,
    PURCHASE: true,
    PURCHASE_FROM_DEVELOPER_PROJECT: true,
    RECONSTRUCTION: true,
    REFINANCING_OF_LOANS: true,
    REIMBURSEMENT_OF_OWN_FUNDS: true,
    SETTLEMENT_OF_CO_OWNERSHIP: true,
  },
  "ReworkLoanPurpose"
);

const ReworkPurpose = optional(
  {
    additionalInfo: ReworkAdditionalInfo,
    creditType: CreditType,
    currentBalance: MoneyAmount,
    financedProperty: t.string,
    financing: ReworkFinancing,
    loanPurpose: ReworkLoanPurpose,
    loanPurposeLabel: t.string,
    loanType: ReworkLoanType,
    provider: t.string,
    purposeId: t.string,
  },
  "ReworkPurpose"
);

export type ReworkPurpose = t.TypeOf<typeof ReworkPurpose>;

const ReworkPurposeAndFinancing = optional(
  {
    purposes: t.array(ReworkPurpose),
  },
  "ReworkPurposeAndFinancing"
);

export type ReworkPurposeAndFinancing = t.TypeOf<
  typeof ReworkPurposeAndFinancing
>;

/**
 * ReworkRegisters
 */
const ReworkCreditCard = optional(
  {
    currentBalance: MoneyAmount,
    contractNumber: t.string,
    creditCardLimit: MoneyAmount,
    newCreditCardLimit: MoneyAmount,
    provider: t.string,
    recordId: t.string,
    repaidBeforeDisbursement: t.boolean,
  },
  "ReworkCreditCard"
);

export type ReworkCreditCard = t.TypeOf<typeof ReworkCreditCard>;

const ReworkExistingApplication = optional(
  {
    creditCardLimit: MoneyAmount,
    monthlyInstallment: MoneyAmount,
    overdraftLimit: MoneyAmount,
    provider: t.string,
    recordId: t.string,
  },
  "ReworkExistingApplication"
);

export type ReworkExistingApplication = t.TypeOf<
  typeof ReworkExistingApplication
>;

const ReworkConsumerLoanOrMortgage = optional(
  {
    approvedCreditAmount: MoneyAmount,
    contractNumber: t.string,
    creditType: CreditType,
    currentBalance: MoneyAmount,
    finalDate: DateFromSQLString,
    isUsedForRefinance: t.boolean,
    loansForLiving: t.boolean,
    monthlyInstallment: MoneyAmount,
    originalLoanPurpose: OriginalLoanPurpose,
    propertyOwner: t.boolean,
    provider: t.string,
    recordId: t.string,
    repaidBeforeDisbursement: t.boolean,
  },
  "ReworkConsumerLoanOrMortgage"
);

export type ReworkConsumerLoanOrMortgage = t.TypeOf<
  typeof ReworkConsumerLoanOrMortgage
>;

const ReworkOverdraft = optional(
  {
    currentBalance: MoneyAmount,
    contractNumber: t.string,
    newOverdraftLimit: MoneyAmount,
    overdraftLimit: MoneyAmount,
    provider: t.string,
    recordId: t.string,
    repaidBeforeDisbursement: t.boolean,
  },
  "ReworkOverdraft"
);

export type ReworkOverdraft = t.TypeOf<typeof ReworkOverdraft>;

const ReworkCreditBureauResult = optional(
  {
    creditCards: t.array(ReworkCreditCard),
    existingApplications: t.array(ReworkExistingApplication),
    existingLoans: t.array(ReworkConsumerLoanOrMortgage),
    overdrafts: t.array(ReworkOverdraft),
  },
  "ReworkCreditBureauResult"
);

export type ReworkCreditBureauResult = t.TypeOf<
  typeof ReworkCreditBureauResult
>;

const ReworkRegisters = t.type(
  {
    creditBureauResults: ApplicantsRecordC(
      optionFromUndefined(ReworkCreditBureauResult),
      option.none
    ),
  },
  "ReworkRegisters"
);

export type ReworkRegisters = t.TypeOf<typeof ReworkRegisters>;

/**
 * ReworkData
 */
export const ReworkData = optional(
  {
    reworkAdditionalDetails: ReworkAdditionalDetails,
    reworkBonita: ReworkBonita,
    reworkClientProfile: ReworkClientProfile,
    reworkOffer: ReworkOffer,
    reworkPropertyAndAppraisal: ReworkPropertyAndAppraisal,
    reworkPurposeAndFinancing: ReworkPurposeAndFinancing,
    reworkRegisters: ReworkRegisters,
  },
  "ReworkData"
);
