import { useMemo, useState } from "react";
import {
  constant,
  constNull,
  constFalse,
  pipe,
  constTrue,
} from "fp-ts/function";
import {
  Form,
  Banner,
  DropdownOption,
  TextChildren,
  FormSection,
  Box,
} from "design-system";
import { LocaleKey, useFormatMessage } from "../../../intl";
import {
  ApplicantIndex,
  LoanList,
  ReworkConsumerLoanOrMortgage,
  ReworkCreditBureauResult,
  ReworkCreditCard,
  ReworkOverdraft,
} from "../../domain";
import { array, boolean, option, taskEither, nonEmptyArray } from "fp-ts";
import { Option } from "fp-ts/Option";
import { SelectedApplicant } from "../../mortgageDashboardUtils";
import { useCommand } from "../../../useAPI";
import * as api from "./api";
import { MoneyAmount } from "../../../globalDomain";
import { MortgageConsumerLoanStack } from "./MortgageConsumerLoanStack";
import { OverdraftStack } from "./OverdraftStack";
import { CreditCardStack } from "./CreditCardStack";
import { AmountLimit } from "../../api";
import { NonEmptyString } from "io-ts-types/lib/NonEmptyString";
import { NonEmptyArray } from "fp-ts/NonEmptyArray";
import * as standardLoanApi from "../../../StandardLoan/ExpensesAndAdditionalIncome/ExpensesConfirmation/CurrentLiabilitiesDialog/api";
import { foldLiabilitiesVariant } from "../../../StandardLoan/domain";
import { useAppContext } from "../../../useAppContext";
import * as mortgageApi from "../../api";
import * as classes from "./Liabilities.treat";
import * as ovdApi from "../../../PreapprovedOverdraft/ExpensesPage/ExpensesConfirmation/CurrentLiabilitiesDialog/api";

export type LiabilitiesVariant = "CF" | "MTG" | "OVD";

type Props = {
  loanList: LoanList;
  onCancel: () => unknown;
  onSave: () => void;
  selectedApplicant: SelectedApplicant;
  isBonitaEvaluated: boolean;
  isACPhase: boolean;
  isViewMode: boolean;
  selectedOfferLoanAmount: Option<MoneyAmount>;
  othersLoanList: Array<{ loanList: Option<LoanList> } & ApplicantIndex>;
  amountLimits: {
    [key in
      | "monthlyInstallmentAmount"
      | "mtgCurrentBalance"
      | "mtgOriginalLoanAmount"
      | "ccAndOvdCurrentBalance"
      | "ccAndOvdLimit"]: AmountLimit;
  };
  providersList: DropdownOption<NonEmptyString>[];
  onEditStart: () => void;
  onEditEnd: () => void;
  variant: LiabilitiesVariant;
  reworkCBResults: Option<ReworkCreditBureauResult>;
  checks: Option<mortgageApi.ApplicationChecksOutput>;
  updateChecks: () => void;
};

const getTotalCurrentBalances = (tot: number, loanList: LoanList) => {
  const sumConsumerLoansOrMortgagesList = loanList.consumerLoansOrMortgagesList.reduce(
    (tot, { usedForRefinance, currentBalance }) =>
      pipe(usedForRefinance, option.getOrElse(constFalse))
        ? tot +
          pipe(
            currentBalance,
            option.map(({ amount }) => amount),
            option.getOrElse(constant(0))
          )
        : tot,
    0
  );
  return tot + sumConsumerLoansOrMortgagesList;
};

type LiabilitiesErrors = "GenericError" | "IsInEditMode";

const liabilitiesErrorsMap = (err: LiabilitiesErrors): LocaleKey => {
  switch (err) {
    case "GenericError":
      return "GenericError";
    case "IsInEditMode":
      return "Mortgage.CBResults.Liabilities.liabilitiesValidationError.IsInEditMode";
  }
};

export function Liabilities(props: Props) {
  const formatMessage = useFormatMessage();
  const {
    config: { enableMtgImprovementsUi },
  } = useAppContext();

  const [loanList, setLoanList] = useState(props.loanList);
  const [isAdding, setIsAdding] = useState<Option<keyof Props["loanList"]>>(
    option.none
  );
  const setAddingProcess = (itemType: Option<keyof Props["loanList"]>) => {
    setIsAdding(itemType);
    pipe(itemType, option.fold(props.onEditEnd, props.onEditStart));
  };

  const [hasEditedOnce, setHasEditedOnce] = useState(false);
  const [hasLiabilities, setHasLiabilities] = useState({
    isConsumerLoansOrMortgagesListAdded: false,
    isCreditCardsListAdded: false,
    isOverdraftsListAdded: false,
  });

  const [formErrors, setFormErrors] = useState<
    Option<NonEmptyArray<TextChildren>>
  >(option.none);
  const [editingRecordID, setEditingRecordID] = useState<
    Option<NonEmptyString>
  >(option.none);
  const setEditingProcess = (recordId: Option<NonEmptyString>) => {
    setEditingRecordID(recordId);
    pipe(recordId, option.fold(props.onEditEnd, props.onEditStart));
  };
  const isInteractingWithAnyForm =
    option.isSome(isAdding) || option.isSome(editingRecordID);

  const submitLabel = pipe(
    props.isBonitaEvaluated && hasEditedOnce,
    boolean.fold(
      () => formatMessage("Mortgage.CBResults.Liabilities.save"),
      () => formatMessage("Mortgage.CBResults.Liabilities.saveUpdateBonita")
    )
  );
  const isNotSentToLf = pipe(
    props.checks,
    option.fold(
      () => false,
      checks =>
        pipe(checks.registers.isNotSentToLf, option.getOrElse(constTrue))
    )
  );

  const deleteLiabilityRecord = useCommand(api.deleteLiabilityRecord);
  const cancelWithoutSaving = useCommand(api.cancelWithoutSaving);
  const saveAndUpdate = useCommand(
    foldLiabilitiesVariant(props.variant, {
      onMtg: () => api.saveAndUpdate,
      onCf: () => standardLoanApi.saveAndUpdate,
      onOvd: () => ovdApi.saveAndUpdate,
    })
  );
  const cancelWithoutSavingStandardLoan = useCommand(
    standardLoanApi.cancelWithoutSaving
  );
  const deleteLiabilityRecordStandardLoan = useCommand(
    standardLoanApi.deleteLiabilityRecord
  );

  const cancelWithoutSavingOverdraft = useCommand(ovdApi.cancelWithoutSaving);

  const deleteLiabilityRecordOverdraft = useCommand(
    ovdApi.deleteLiabilityRecord
  );

  const removeLoanRecord = (recordId: NonEmptyString) =>
    pipe(
      foldLiabilitiesVariant(props.variant, {
        onMtg: () =>
          deleteLiabilityRecord({
            applicantIndex: props.selectedApplicant.index,
            recordId,
          }),
        onCf: () => deleteLiabilityRecordStandardLoan({ recordId }),
        onOvd: () => deleteLiabilityRecordOverdraft({ recordId }),
      }),
      taskEither.chain(() =>
        taskEither.fromIO(() =>
          setLoanList({
            consumerLoansOrMortgagesList: loanList.consumerLoansOrMortgagesList.filter(
              c => c.recordId !== recordId
            ),
            creditCardsList: loanList.creditCardsList.filter(
              c => c.recordId !== recordId
            ),
            overdraftsList: loanList.overdraftsList.filter(
              c => c.recordId !== recordId
            ),
          })
        )
      ),
      taskEither.chain(() => taskEither.fromIO(props.updateChecks))
    )();

  const otherApplicantsTotalLoanAmount = pipe(
    props.othersLoanList,
    array.map(({ loanList }) => loanList),
    array.compact,
    array.reduce(0, getTotalCurrentBalances)
  );

  const totalLoanAmount = useMemo(
    () => getTotalCurrentBalances(0, loanList) + otherApplicantsTotalLoanAmount,
    [loanList, otherApplicantsTotalLoanAmount]
  );

  const atLeastOneLiability =
    loanList.consumerLoansOrMortgagesList.length > 0 ||
    loanList.creditCardsList.length > 0 ||
    loanList.overdraftsList.length > 0;

  const areLiabilitiesAdded = (): boolean => {
    const {
      isConsumerLoansOrMortgagesListAdded,
      isCreditCardsListAdded,
      isOverdraftsListAdded,
    } = hasLiabilities;
    return (
      (isConsumerLoansOrMortgagesListAdded ||
        isCreditCardsListAdded ||
        isOverdraftsListAdded) &&
      props.variant === "OVD"
    );
  };

  const formButtons = pipe(
    props.isViewMode,
    boolean.fold(
      constant({
        submitButton: {
          label: submitLabel,
          action: pipe(
            option.isNone(isAdding) && option.isNone(editingRecordID),
            boolean.fold(
              () => taskEither.left("IsInEditMode" as const),
              () =>
                pipe(
                  props.variant !== "OVD"
                    ? saveAndUpdate()
                    : areLiabilitiesAdded()
                    ? saveAndUpdate()
                    : cancelWithoutSavingOverdraft(),
                  taskEither.mapLeft<unknown, LiabilitiesErrors>(
                    () => "GenericError" as const
                  )
                )
            ),
            taskEither.chain(() => taskEither.fromIO(props.onSave)),
            taskEither.orElse(err =>
              taskEither.fromIO(() =>
                setFormErrors(
                  option.some(
                    nonEmptyArray.of(
                      formatMessage(liabilitiesErrorsMap(err)) as TextChildren
                    )
                  )
                )
              )
            )
          ),
        },
        cancelButton: {
          label: formatMessage("Mortgage.CBResults.Liabilities.cancel"),
          action: pipe(
            props.onCancel,
            taskEither.fromIO,
            taskEither.chain(() =>
              foldLiabilitiesVariant(props.variant, {
                onMtg: () =>
                  cancelWithoutSaving({
                    applicantIndex: props.selectedApplicant.index,
                  }),
                onCf: () => cancelWithoutSavingStandardLoan(),
                onOvd: () => cancelWithoutSavingOverdraft(),
              })
            )
          ),
        },
      }),
      constant({})
    )
  );

  const getReworkConsumerLoan = (
    recordId: NonEmptyString
  ): Option<ReworkConsumerLoanOrMortgage> =>
    pipe(
      props.reworkCBResults,
      option.chain(({ existingLoans }) => existingLoans),
      option.chain(
        array.findFirst(loan =>
          pipe(
            loan.recordId,
            option.exists(id => id === recordId)
          )
        )
      )
    );

  const getReworkOverdraft: (
    recordId: NonEmptyString
  ) => Option<ReworkOverdraft> = recordId =>
    pipe(
      props.reworkCBResults,
      option.chain(reworkCBResults => reworkCBResults.overdrafts),
      option.chain(overdrafts =>
        pipe(
          overdrafts,
          array.findFirst(overdrafts =>
            pipe(
              overdrafts.recordId,
              option.exists(rId => rId === recordId)
            )
          )
        )
      )
    );

  const getReworkCreditCard: (
    recordId: NonEmptyString
  ) => Option<ReworkCreditCard> = recordId =>
    pipe(
      props.reworkCBResults,
      option.chain(reworkCBResults => reworkCBResults.creditCards),
      option.chain(creditCards =>
        pipe(
          creditCards,
          array.findFirst(creditCards =>
            pipe(
              creditCards.recordId,
              option.exists(rId => rId === recordId)
            )
          )
        )
      )
    );

  const renderMTGInfoBanner = (
    <Banner
      type="informative"
      content={formatMessage("Mortgage.CBResults.Liabilities.editInfo")}
      actions={option.none}
      onDismiss={option.none}
      title={option.none}
    />
  );

  const renderCFInfoBanner = (
    <Banner
      type="informative"
      content={formatMessage(
        "StandardLoan.ExpensesConfirmation.Liabilities.Dialog.editInfo"
      )}
      actions={option.none}
      onDismiss={option.none}
      title={option.none}
    />
  );

  return (
    <Box grow shrink column className={classes.margins}>
      <Form {...formButtons}>
        <FormSection errors={formErrors}>
          {props.variant === "CF"
            ? renderCFInfoBanner
            : enableMtgImprovementsUi
            ? !props.isViewMode && atLeastOneLiability && renderMTGInfoBanner
            : !props.isViewMode && renderMTGInfoBanner}
          <MortgageConsumerLoanStack
            isViewMode={props.isViewMode}
            isAdding={pipe(
              isAdding,
              option.exists(adding => adding === "consumerLoansOrMortgagesList")
            )}
            editingRecordID={editingRecordID}
            isInteractingWithAnyForm={isInteractingWithAnyForm}
            consumerLoansOrMortgagesList={loanList.consumerLoansOrMortgagesList}
            selectedApplicant={props.selectedApplicant}
            onCheck={(credit, value: boolean) => {
              setHasEditedOnce(true);
              setLoanList(loanList => ({
                ...loanList,
                consumerLoansOrMortgagesList: loanList.consumerLoansOrMortgagesList.map(
                  c => ({
                    ...c,
                    usedForRefinance:
                      c.recordId === credit.recordId
                        ? option.some(value)
                        : c.usedForRefinance,
                  })
                ),
              }));
            }}
            onRemove={recordId => {
              removeLoanRecord(recordId);
              setHasLiabilities(prevState => ({
                ...prevState,
                isConsumerLoansOrMortgagesListAdded: true,
              }));
            }}
            onEditing={recordId => setEditingProcess(option.some(recordId))}
            onEdit={credit => {
              setLoanList(credits => ({
                ...credits,
                consumerLoansOrMortgagesList: credits.consumerLoansOrMortgagesList.map(
                  c => (c.recordId === credit.recordId ? credit : c)
                ),
              }));
              setHasEditedOnce(true);
              setEditingProcess(option.none);
              props.updateChecks();
              setHasLiabilities(prevState => ({
                ...prevState,
                isConsumerLoansOrMortgagesListAdded: true,
              }));
            }}
            onAdd={loan => {
              setLoanList(loans => ({
                ...loans,
                consumerLoansOrMortgagesList: loans.consumerLoansOrMortgagesList.concat(
                  loan
                ),
              }));
              setHasEditedOnce(true);
              setAddingProcess(option.none);
              props.updateChecks();
              setHasLiabilities(prevState => ({
                ...prevState,
                isConsumerLoansOrMortgagesListAdded: true,
              }));
            }}
            onCancel={() => {
              setEditingProcess(option.none);
              setAddingProcess(option.none);
            }}
            onAdding={() =>
              setAddingProcess(option.some("consumerLoansOrMortgagesList"))
            }
            providersList={props.providersList}
            amountLimits={{
              monthlyInstallmentAmount:
                props.amountLimits.monthlyInstallmentAmount,
              mtgCurrentBalance: props.amountLimits.mtgCurrentBalance,
              mtgOriginalLoanAmount: props.amountLimits.mtgOriginalLoanAmount,
            }}
            isACPhase={props.isACPhase}
            variant={props.variant}
            getReworkConsumerLoan={getReworkConsumerLoan}
          />

          <OverdraftStack
            isViewMode={props.isViewMode}
            isAdding={pipe(
              isAdding,
              option.exists(adding => adding === "overdraftsList")
            )}
            editingRecordID={editingRecordID}
            isInteractingWithAnyForm={isInteractingWithAnyForm}
            overdraftsList={loanList.overdraftsList}
            selectedApplicant={props.selectedApplicant}
            onRemove={recordId => {
              removeLoanRecord(recordId);
              setHasLiabilities(prevState => ({
                ...prevState,
                isOverdraftsListAdded: true,
              }));
            }}
            onEditing={recordId => setEditingProcess(option.some(recordId))}
            onEdit={credit => {
              setLoanList(credits => ({
                ...credits,
                overdraftsList: credits.overdraftsList.map(c =>
                  c.recordId === credit.recordId ? credit : c
                ),
              }));
              setHasEditedOnce(true);
              setEditingProcess(option.none);
              props.updateChecks();
              setHasLiabilities(prevState => ({
                ...prevState,
                isOverdraftsListAdded: true,
              }));
            }}
            onCancel={() => {
              setEditingProcess(option.none);
              setAddingProcess(option.none);
            }}
            onAdding={() => setAddingProcess(option.some("overdraftsList"))}
            onAdd={overdraft => {
              setLoanList(loans => ({
                ...loans,
                overdraftsList: loans.overdraftsList.concat(overdraft),
              }));
              setHasEditedOnce(true);
              setAddingProcess(option.none);
              props.updateChecks();
              setHasLiabilities(prevState => ({
                ...prevState,
                isOverdraftsListAdded: true,
              }));
            }}
            providersList={props.providersList}
            amountLimits={{
              ccAndOvdCurrentBalance: props.amountLimits.ccAndOvdCurrentBalance,
              ccAndOvdLimit: props.amountLimits.ccAndOvdLimit,
            }}
            isACPhase={props.isACPhase}
            variant={props.variant}
            getReworkOverdraft={getReworkOverdraft}
            getReworkConsumerLoan={getReworkConsumerLoan}
          />

          <CreditCardStack
            isViewMode={props.isViewMode}
            isAdding={pipe(
              isAdding,
              option.exists(adding => adding === "creditCardsList")
            )}
            editingRecordID={editingRecordID}
            isInteractingWithAnyForm={isInteractingWithAnyForm}
            creditCardsList={loanList.creditCardsList}
            selectedApplicant={props.selectedApplicant}
            onEdit={credit => {
              setLoanList(credits => ({
                ...credits,
                creditCardsList: credits.creditCardsList.map(c =>
                  c.recordId === credit.recordId ? credit : c
                ),
              }));
              setHasEditedOnce(true);
              setEditingProcess(option.none);
              props.updateChecks();
              setHasLiabilities(prevState => ({
                ...prevState,
                isCreditCardsListAdded: true,
              }));
            }}
            onAdd={creditCard => {
              setLoanList(loans => ({
                ...loans,
                creditCardsList: loans.creditCardsList.concat(creditCard),
              }));
              setHasEditedOnce(true);
              setAddingProcess(option.none);
              props.updateChecks();
              setHasLiabilities(prevState => ({
                ...prevState,
                isCreditCardsListAdded: true,
              }));
            }}
            onCancel={() => {
              setEditingProcess(option.none);
              setAddingProcess(option.none);
            }}
            onAdding={() => setAddingProcess(option.some("creditCardsList"))}
            onRemove={recordId => {
              removeLoanRecord(recordId);
              setHasLiabilities(prevState => ({
                ...prevState,
                isCreditCardsListAdded: true,
              }));
            }}
            onEditing={recordId => setEditingProcess(option.some(recordId))}
            providersList={props.providersList}
            amountLimits={{
              ccAndOvdCurrentBalance: props.amountLimits.ccAndOvdCurrentBalance,
              ccAndOvdLimit: props.amountLimits.ccAndOvdLimit,
            }}
            isACPhase={props.isACPhase}
            variant={props.variant}
            getReworkCreditCard={getReworkCreditCard}
            getReworkConsumerLoan={getReworkConsumerLoan}
          />
          {pipe(
            props.selectedOfferLoanAmount,
            option.filter(
              ({ amount: offerLoanAmount }) => totalLoanAmount > offerLoanAmount
            ),
            option.fold(constNull, () => (
              <Banner
                type="warning"
                content={formatMessage(
                  "Mortgage.CBResults.selectedLoansWarning"
                )}
                actions={option.none}
                onDismiss={option.none}
                title={option.none}
              />
            ))
          )}
          {enableMtgImprovementsUi && isNotSentToLf && (
            <Banner
              actions={option.none}
              content={formatMessage(
                "Mortgage.CBResults.Liabilities.notSentToLf"
              )}
              title={option.none}
              onDismiss={option.none}
              type="informative"
            />
          )}
        </FormSection>
      </Form>
    </Box>
  );
}
