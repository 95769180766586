import { Box, DropdownOption, Stack } from "design-system";
import { LiabilitySection } from "./LiabilitySection";
import { array, option } from "fp-ts";
import { pipe, constFalse, constTrue, constVoid } from "fp-ts/function";
import { Option } from "fp-ts/Option";
import {
  Overdraft,
  LoanList,
  ReworkOverdraft,
  ReworkConsumerLoanOrMortgage,
} from "../../domain";
import { useFormatMessage } from "../../../intl";
import { SelectedApplicant } from "../../mortgageDashboardUtils";
import { EditSection } from "./EditSection";
import { OverdraftForm } from "./OverdraftForm";
import { NonEmptyString } from "io-ts-types/NonEmptyString";
import { AmountLimit } from "../../api";
import { LiabilitiesVariant } from "./Liabilities";

type Props = {
  isInteractingWithAnyForm: boolean;
  overdraftsList: Array<Overdraft>;
  selectedApplicant: SelectedApplicant;
  isAdding: boolean;
  isACPhase: boolean;
  isViewMode: boolean;
  editingRecordID: Option<NonEmptyString>;

  onEdit: (overdraft: Overdraft) => unknown;
  onCancel: () => unknown;
  onAdding: (type: keyof LoanList) => unknown;
  onAdd: (overdraft: Overdraft) => unknown;
  onRemove: (recordId: NonEmptyString) => unknown;
  onEditing: (recordId: NonEmptyString) => unknown;
  providersList: DropdownOption<NonEmptyString>[];
  amountLimits: {
    [key in "ccAndOvdCurrentBalance" | "ccAndOvdLimit"]: AmountLimit;
  };
  variant: LiabilitiesVariant;
  getReworkOverdraft: (recordId: NonEmptyString) => Option<ReworkOverdraft>;
  getReworkConsumerLoan: (
    recordId: NonEmptyString
  ) => Option<ReworkConsumerLoanOrMortgage>;
};

export function OverdraftStack(props: Props) {
  const formatMessage = useFormatMessage();

  const { left: overdraftsList, right: manuallyAddedOverdraftsList } = pipe(
    props.overdraftsList,
    array.partition(overdraft => overdraft.manuallyAdded)
  );

  const overdraftsFormat = (credit: Overdraft) => {
    const reworkOverdraft = props.getReworkOverdraft(credit.recordId);

    return (
      <Box grow shrink column key={credit.recordId}>
        <OverdraftForm
          initialValues={option.some(credit)}
          onSave={props.onEdit}
          onCancel={props.onCancel}
          onEdit={() => props.onEditing(credit.recordId)}
          onRemove={() => props.onRemove(credit.recordId)}
          isACPhase={props.isACPhase}
          isInteractingWithAnyForm={props.isInteractingWithAnyForm}
          selectedApplicant={props.selectedApplicant}
          providersList={props.providersList}
          amountLimits={props.amountLimits}
          isViewMode={props.isViewMode}
          edit={
            !props.isViewMode &&
            pipe(
              props.editingRecordID,
              option.filter(id => id === credit.recordId),
              option.fold(constFalse, constTrue)
            )
          }
          variant={props.variant}
          reworkOverdraft={reworkOverdraft}
        />
      </Box>
    );
  };

  const newOverdraftData = {
    label: formatMessage("Mortgage.CBResults.Liabilities.addOverdrafts"),
    action: () => props.onAdding("overdraftsList"),
    disabled: props.isInteractingWithAnyForm,
  };

  return (
    <Stack key="overdraftsSection" units={4} column>
      <LiabilitySection
        key="overdrafts"
        title={formatMessage("Mortgage.CBResults.Liabilities.overdrafts")}
        children={overdraftsList.map(overdraftsFormat)}
        addNew={pipe(
          newOverdraftData,
          option.fromPredicate(
            () =>
              !props.isViewMode &&
              !props.isAdding &&
              manuallyAddedOverdraftsList.length === 0
          )
        )}
      />
      {manuallyAddedOverdraftsList.length > 0 && (
        <LiabilitySection
          key="manuallyAddedOverdrafts"
          title={formatMessage(
            "Mortgage.CBResults.Liabilities.manuallyAddedOverdrafts"
          )}
          children={manuallyAddedOverdraftsList.map(overdraftsFormat)}
          addNew={pipe(
            newOverdraftData,
            option.fromPredicate(() => !props.isAdding && !props.isAdding)
          )}
        />
      )}
      {props.isAdding && (
        <EditSection
          title={formatMessage("Liabilities.AddOverdraft.formSectionTitle")}
        >
          <OverdraftForm
            initialValues={option.none}
            onSave={props.onAdd}
            onCancel={props.onCancel}
            onEdit={constVoid}
            onRemove={constVoid}
            isACPhase={props.isACPhase}
            isInteractingWithAnyForm={props.isInteractingWithAnyForm}
            selectedApplicant={props.selectedApplicant}
            providersList={props.providersList}
            amountLimits={props.amountLimits}
            edit={!props.isViewMode}
            isViewMode={props.isViewMode}
            variant={props.variant}
            reworkOverdraft={option.none}
          />
        </EditSection>
      )}
    </Stack>
  );
}
