import {
  Card,
  FormSection,
  Box,
  Children,
  LocalizedString,
} from "design-system";

import * as classes from "./EditSection.treat";

type Props = {
  children: Children;
  title: LocalizedString;
};

export function EditSection(props: Props) {
  return (
    <Box column grow shrink className={classes.container}>
      <Card>
        <FormSection
          heading={{
            title: props.title,
          }}
        >
          {props.children}
        </FormSection>
      </Card>
    </Box>
  );
}
